import React, { useEffect, useState, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";

//jQuery initialization
import $ from "jquery";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  getSystemSettings,
} from "../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL, demo } from "../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";

//context consumer
import { SettingsContext } from "../../../contexts/Settings";
import { UserContext } from "../../../contexts/User";
import { RestaurantContext } from "../../../contexts/Restaurant";
import { FoodContext } from "../../../contexts/Food";

const cookies = new Cookies();

function LoginForm({ navigateTo, setLoginModal, checkLoginfunc }) {
  const { t, i18n } = useTranslation();

  const history = useHistory();

  //getting context values here
  let {
    generalSettings,
    getSmtp,
    getPermissionGroups,
    getLanguages,
    getCurrency,
    getSettings,
  } = useContext(SettingsContext);
  let { getAuthUser, authUserInfo, getCustomer, getWaiter, getAdminStaff } =
    useContext(UserContext);
  let {
    getBranch,
    getTable,
    getDeptTag,
    getPaymentType,
    getWorkPeriod,
    getDeliveryPendingOrders,
    getDeliveryDeliveredOrders,
  } = useContext(RestaurantContext);

  let { getFood, getFoodGroup, getPropertyGroup, getVariation } =
    useContext(FoodContext);
  const [credentials, setCredentials] = useState({
    email: null,
    password: null,
    remember_me: false,
    install_check_reload: true,
    install_no: false,
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const url = BASE_URL + "/auth/login";
    return axios
      .post(url, credentials)
      .then((res) => {
        if (res.data[2] === 0 || res.data[2] === "0") {
          let access_token = {
            _user: res.data[0].access_token.slice(0, 8),
            sbb: res.data[0].access_token.slice(8, 10),
            frr: res.data[0].access_token.slice(10, 13),
            xss: res.data[0].access_token.slice(13),
          };
          let date = new Date();
          date.setFullYear(date.getFullYear() + 1);
          cookies.set("_user", access_token._user, {
            path: "/",
            expires: date,
            sameSite: "lax",
          });
          cookies.set("sbb", access_token.sbb, {
            path: "/",
            expires: date,
            sameSite: "lax",
          });
          cookies.set("frr", access_token.frr, {
            path: "/",
            expires: date,
            sameSite: "lax",
          });
          cookies.set("xss", access_token.xss, {
            path: "/",
            expires: date,
            sameSite: "lax",
          });
          // todo:: get data if have permission here after authentication
          //common
          getAuthUser();
          getPermissionGroups();
          getBranch();
          getLanguages();
          getCurrency();

          //permission based -data[3] permissions of this user
          if (res.data[3] !== null && res.data[3].includes("Manage")) {
            getSmtp();
          }
          if (res.data[3] !== null) {
            if (
              res.data[3].includes("Customer") ||
              res.data[3].includes("POS")
            ) {
              getAdminStaff();
              getWorkPeriod();
              getCustomer();
              getTable();
              getWaiter();
              getDeptTag();
              getPaymentType();
              getFood();
              getFoodGroup();
              getPropertyGroup();
              getVariation();
            }
            if (res.data[3].includes("Delivery")) {
              getDeliveryPendingOrders();

              getDeliveryDeliveredOrders();
            }
          }
          setLoading(false);

          if (res.data[1].user_type === "customer") {
            setLoginModal(false);
            if (checkLoginfunc) {
              checkLoginfunc();
            }
            history.push(navigateTo);
          } else {
            setLoginModal(false);
            if (checkLoginfunc) {
              checkLoginfunc();
            }
            history.push(navigateTo);
          }
        } else {
          setLoading(false);
          toast.error(`${_t(t("Sorry, you do not have access!"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(`${_t(t("Email or password is wrong!"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };
  const handleCredentials = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
      remember_me: !credentials.remember_me,
    });
  };
  return (
    <div key="loading">
      <>
        <h3 className="mt-2 mb-2 text-capitalize font-weight-bold">
          {_t(t("sign in"))}
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* demo login info */}
            <div className="col-12 t-mb-15">
              <input
                onChange={handleCredentials}
                type="email"
                name="email"
                placeholder="Email"
                value={credentials.email}
                required
                autoComplete="off"
                className="form-control border-0 rounded-1"
              />
            </div>
            <div className="col-12 t-mb-15">
              <input
                onChange={handleCredentials}
                name="password"
                type="password"
                placeholder="Password"
                value={credentials.password}
                required
                autoComplete="off"
                className="form-control border-0 rounded-1"
              />
            </div>
            <div className="col-6 t-mb-15">
              <label className="mx-checkbox">
                <input
                  onChange={handleCredentials}
                  name="remember_me"
                  type="checkbox"
                  className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                />
                <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8">
                  {_t(t("Remember Me"))}
                </span>
              </label>
            </div>
            <div className="col-6 t-mb-15 text-right">
              <NavLink to="/reset-password" className="t-link sm-text">
                {_t(t("Forgot password"))}?
              </NavLink>
            </div>
            <div className="col-12">
              <div className="d-flex align-items-center">
                <div className="t-mr-8">
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn btn-success sm-text text-uppercase"
                  >
                    {loading ? "Processing..." : _t(t("sign in"))}
                  </button>
                </div>
                <div className="t-mr-8">
                  <NavLink
                    to="/sign-up"
                    className="btn btn-primary sm-text text-uppercase"
                  >
                    {_t(t("Sign Up"))}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    </div>
  );
}

export default LoginForm;
