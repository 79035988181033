import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Paper, Button } from "@mui/material";
//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { FoodContext } from "../../../../contexts/Food";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { SettingsContext } from "../../../../contexts/Settings";
import cafelogo from "../../../../assets/images/cafelogo.png";
import mapIcon from "../../../../assets/images/mapIcon.png";
import phoneIcon from "../../../../assets/images/icon _phone.png";
import cart from "../../../../assets/images/cart.png";
import reserve_online from "../../../../assets/images/reserve-online.png";
import recent_deal_1 from "../../../../assets/images/recent_deal_1.png";
import recent_deal_2 from "../../../../assets/images/recent_deal_2.png";
import crunchy from "../../../../assets/images/crunchy.png";
import biryani from "../../../../assets/images/biryani.png";
import creamy from "../../../../assets/images/creamy.png";
import android_playstore from "../../../../assets/images/android-playstore.png";
import iphone_playstore from "../../../../assets/images/iphone-playstore.png";
// import MenuAccordion from "./MenuAccordion";
//axios and base url
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import axios from "axios";
import { BASE_URL, WEBSITE_URL } from "../../../../BaseUrl";
import { Box, TextField, Typography, Container } from "@mui/material";
import { styled } from "@mui/system";

import ".././onlineorder.css";
import ".././card.css";
import ".././menuaccordion.css";
import "./FAQ.css";
//functions
import {
  _t,
  modalLoading,
  restaurantMenuLink,
  getSystemSettings,
  getCookie,
  deleteCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
} from "../../../../functions/Functions";

//3rd party packages
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//jQuery initialization
import $ from "jquery";
import { useFlutterwave } from "react-flutterwave";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import NewHeader from ".././component/NewHeader";
import NewFooter from ".././component/NewFooter";
const FAQs = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  //getting context values here
  let {
    navLanguageList,
    navCurrencyList,
    generalSettings,
    showManageStock,
    paypal_client_id,
  } = useContext(SettingsContext);
  //auth user
  const { authUserInfo } = useContext(UserContext);
  //restaurant
  let { branchForSearch } = useContext(RestaurantContext);
  //food
  console.log("branchForSearch", branchForSearch);
  let {
    getFoodWeb,
    foodListWeb,
    foodGroupWeb,
    propertyGroupWeb,
    workPeriodWeb,
    foodStockWeb,
    branches,
    getBranchesWeb,
    setFoodStockWeb,
  } = useContext(FoodContext);
  // this is comment
  //use state
  const [loading, setLoading] = useState(true);
  const [showCart, setShowCart] = useState(false);
  const [expanded, setExpanded] = useState("panel1");
  const [showCheckout, setShowCheckout] = useState(false);
  const [showVariation, setShowVariation] = useState(false);

  // use showPromotion
  const [showPromotion, setshowPromotion] = useState("NO");

  // stripe
  const [showStripe, setshowStripe] = useState("");

  // flutterwave details
  const [flutterwaveDetails, setflutterwaveDetails] = useState({
    publicKey: null,
    secretKey: null,
    fw_currency_code: null,
    show: "NO",
  });

  // paypal
  const [showPaypal, setshowPaypal] = useState("");

  // branch delivery charge
  const [branchDeliveryCharge, setBranchDeliveryCharge] = useState(0);

  // active branch
  let ActiveBranch =
    branchForSearch && branchForSearch.filter((branch) => branch.active == 1);

  const [deliverymenShow, setdeliverymenShow] = useState(false);
  const [checkLoginUser, setCheckLoginUser] = useState(0);
  //
  const [defaultLang, setDefaultLang] = useState(null);
  const [defaultCurrency, setDefaultCurrency] = useState(null);

  //food and group
  const [foodItems, setFoodItems] = useState({
    list: null, //default on render
    group: null, //default on render
    selectedItem: null, //on select
    variations: null,
    properties: null,
  });
  const [foodItemsList, setFoodItemsList] = useState({
    list: null, //default on render
    group: null, //default on render
    selectedItem: null, //on select
    variations: null,
    properties: null,
  });

  //new order
  const [newOrder, setNewOrder] = useState({
    variation: null,
    quantity: 1,
    properties: null,
  });

  //order details
  const [orderDetails, setOrderDetails] = useState({
    items: [],
    branch: null, //on select branch
    workPeriod: null, // on select branch
    workPeriodStatus: false,
    address: null, //null get on render user info
    name: null, //get on render
    phn_no: null, //get on render
    note: null,
    payment_type: "COD",
    uploading: false,
  });

  //get sliders values
  const [allSliders, setallSliders] = useState(null);
  const [activeBranch, setactiveBranch] = useState(null);
  const [userPostCode, setUserPostCode] = useState(null);

  // check auth user
  const checkLoginfunc = () => {
    getCookie() !== undefined ? setCheckLoginUser(0) : setCheckLoginUser(1);
  };

  // show stripe
  const showstripebtn = (e) => {
    setLoading(true);
    const url = BASE_URL + `/settings/show-stripe-btn`;
    return axios.get(url).then((res) => {
      setshowStripe(res.data);
      setLoading(false);
    });
  };

  // get flutterwave info
  const getFlutterwaveInfo = () => {
    setLoading(true);
    const url = BASE_URL + `/settings/get-flutterwave-info`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setflutterwaveDetails({
          ...flutterwaveDetails,
          publicKey: res.data.FLUTTERWAVE_PUBLIC_KEY,
          secretKey: res.data.FLUTTERWAVE_SECRET_KEY,
          fw_currency_code: res.data.FLUTTERWAVE_CURRENCY_CODE,
          show: res.data.FLUTTERWAVE_SHOW,
        });

        setLoading(false);
      });
  };

  // show paypal
  const showPaypalBtn = (e) => {
    setLoading(true);

    const url = BASE_URL + `/settings/show-paypal-btn`;
    return axios.get(url).then((res) => {
      setshowStripe(res.data);
      setLoading(false);
    });
  };

  // slider settings
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    className: "",
    // responsive: [
    //     {
    //         breakpoint: 1024,
    //         settings: {
    //             slidesToShow: 3,

    //         }
    //     },
    //     {
    //         breakpoint: 600,
    //         settings: {
    //             slidesToShow: 1,

    //         }
    //     }

    // ]
  };

  //   get all sliders item
  const getSliders = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-hero-sliders";
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallSliders(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //useeffect == componentDidMount()
  useEffect(() => {
    handleJquery();
    getFoodWeb();
    getBranchesWeb();
    getSliders();
    deliveryMenu();
    handleOnLoadDefaultLang();
    handleOnLoadDefaultCurrency();
    checkLoginfunc();
    // showstripebtn();
    setOrderDetails({
      ...orderDetails,
      address: authUserInfo.details && authUserInfo.details.address,
      name: authUserInfo.details && authUserInfo.details.name,
      phn_no: authUserInfo.details && authUserInfo.details.phn_no,
    });
    if (foodGroupWeb && foodListWeb) {
      // let temp = foodListWeb.filter((foodItem, foodItemIndex) => {
      //   return parseInt(foodItem.food_group_id) === foodGroupWeb[0].id;
      // });
      let temp = [];
      for (let i = 0; i < foodGroupWeb.length; i++) {
        temp.push({ ...foodGroupWeb[i], child: [] });
      }

      foodListWeb.forEach((foodItem) => {
        const groupId = parseInt(foodItem.food_group_id);
        for (let i = 0; i < temp.length; i++) {
          if (temp[i].id === groupId) {
            temp[i].child.push(foodItem);
            break;
          }
        }
      });
      setExpanded(temp[0].id);
      setFoodItemsList(temp);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, [authUserInfo, navCurrencyList]);

  // useeffect for stripe payment
  useEffect(() => {
    showstripebtn();
    getFlutterwaveInfo();
  }, []);

  // deliveryman menu update
  const deliveryMenu = () => {
    const url = BASE_URL + `/settings/deliverymen-menu-info`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data.length == 0 || res.data[0].value == 1) {
          setdeliverymenShow(true);
        } else {
          setdeliverymenShow(false);
        }
      });
  };

  //handle jQuery
  const handleJquery = () => {
    $(window).on("scroll", function () {
      var toTopVisible = $("html").scrollTop();
      if (toTopVisible > 500) {
        $(".scrollup").fadeIn();
      } else {
        $(".scrollup").fadeOut();
      }
    });

    // MouseHover Animation home 1
    var hoverLayer = $(".banner-area");
    var heroImgOne = $(".p-shape-1");
    var heroImgTwo = $(".p-shape-2");
    var heroImgThree = $(".p-shape-3");
    var heroImgFour = $(".p-shape-4");
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 100;
      var valueY = (e.pageY * -1) / 120;
      heroImgOne.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 60;
      var valueY = (e.pageY * -1) / 80;
      heroImgTwo.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 40;
      var valueY = (e.pageY * -1) / 60;
      heroImgThree.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 80;
      var valueY = (e.pageY * -1) / 100;
      heroImgFour.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });

    // MouseHover Animation home 2
    var hoverLayer2 = $(".burger-promo-area");
    var heroImgfive = $(".bs1");
    var heroImgsix = $(".bs2");
    var heroImgseven = $(".bs5");
    var heroImgeight = $(".bs6");
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 100;
      var valueY = (e.pageY * -1) / 120;
      heroImgfive.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 60;
      var valueY = (e.pageY * -1) / 80;
      heroImgsix.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 40;
      var valueY = (e.pageY * -1) / 60;
      heroImgseven.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 80;
      var valueY = (e.pageY * -1) / 100;
      heroImgeight.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });

    // MouseHover Animation home 3
    var hoverLayer3 = $(".snack-section");
    var heroImgnine = $(".ss1");
    var heroImgten = $(".ss2");
    var heroImgeleven = $(".ss3");
    var heroImgtweleve = $(".mss2");
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 100;
      var valueY = (e.pageY * -1) / 120;
      heroImgtweleve.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 60;
      var valueY = (e.pageY * -1) / 80;
      heroImgnine.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 40;
      var valueY = (e.pageY * -1) / 60;
      heroImgten.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 80;
      var valueY = (e.pageY * -1) / 100;
      heroImgeleven.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
  };

  //dynamic style
  const style = {
    logo: {
      backgroundImage:
        generalSettings &&
        `url(${getSystemSettings(generalSettings, "type_logo")})`,
    },
    currency: {
      backgroundColor:
        generalSettings && getSystemSettings(generalSettings, "type_clock"),
      color:
        generalSettings && getSystemSettings(generalSettings, "type_color"),
    },
  };

  //logout
  const handleLogout = () => {
    deleteCookie();
  };

  //orders variation
  const handleOrderItemVariation = (item) => {
    setNewOrder({
      ...newOrder,
      variation: item,
    });
  };

  //property
  const handleOrderItemProperty = (propertyItem) => {
    let newTemp = [];
    if (newOrder.properties !== null && newOrder.properties.length > 0) {
      let checkExist = newOrder.properties.find((exist) => {
        return exist.id === propertyItem.id;
      });
      if (checkExist === undefined) {
        newOrder.properties.map((oldItem) => {
          newTemp.push(oldItem);
        });
        propertyItem.quantity = 1;
        newTemp.push(propertyItem);
      } else {
        newOrder.properties.map((oldItem) => {
          if (oldItem.id !== propertyItem.id) {
            newTemp.push(oldItem);
          }
        });
      }
      setNewOrder({
        ...newOrder,
        properties: newTemp,
      });
    } else {
      propertyItem.quantity = 1;
      setNewOrder({
        ...newOrder,
        properties: [propertyItem],
      });
    }
  };

  //multiple qty
  const checkedProperty = (eachItem) => {
    if (newOrder.properties !== null) {
      let findChecked = newOrder.properties.find((checkIt) => {
        return checkIt.id === eachItem.id;
      });
      if (findChecked === undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  //property checked
  const checkCheckedPropertyQuantity = (propertyItem) => {
    if (newOrder.properties !== null) {
      let theItem = newOrder.properties.find((findThis) => {
        return findThis.id === propertyItem.id;
      });
      if (theItem !== undefined) {
        return theItem.quantity;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  };

  //set propertyqty
  const handlePropertyQty = (propertyItem, action) => {
    let newTemp = [];
    if (newOrder.properties !== null && newOrder.properties.length > 0) {
      newOrder.properties.map((pushThis) => {
        if (pushThis.id === propertyItem.id) {
          if (action === "+") {
            pushThis.quantity = pushThis.quantity + 1;
            newTemp.push(pushThis);
          } else {
            if (pushThis.quantity > 1) {
              pushThis.quantity = pushThis.quantity - 1;
            }
            newTemp.push(pushThis);
          }
        } else {
          newTemp.push(pushThis);
        }
      });
      setNewOrder({
        ...newOrder,
        properties: newTemp,
      });
    }
  };

  //get already ordered qty
  const handleAlreadyOrderedQty = (id) => {
    let temp = 0;
    if (orderDetails.items.length > 0) {
      orderDetails.items.map((item) => {
        if (parseInt(item.item.id) === id) {
          temp += parseInt(item.quantity);
        }
      });
    }
    return temp;
  };

  //add to cart
  const handleOrder = () => {
    // check if manage stock is enable
    if (showManageStock) {
      if (
        // handleGetStock(foodItems.selectedItem.id) >
        // handleAlreadyOrderedQty(foodItems.selectedItem.id)
        foodItems.selectedItem?.stocks?.status === false //new condition for ingredient checking
      ) {
        let tempPrice = 0;
        //check variation change hoise
        if (newOrder.variation !== null) {
          tempPrice =
            parseFloat(foodItems.selectedItem.price) +
            parseFloat(newOrder.variation.food_with_variation_price);
        } else {
          tempPrice = parseFloat(foodItems.selectedItem.price);
        }

        if (newOrder.properties !== null && newOrder.properties.length > 0) {
          let tempPropertyPrice = 0;
          newOrder.properties.map((propertyItem, propertyItemIndex) => {
            tempPropertyPrice =
              tempPropertyPrice +
              parseFloat(propertyItem.extra_price) * propertyItem.quantity;
          });
          tempPrice = tempPrice + tempPropertyPrice;
        }

        let tempOrderItem = {
          item: foodItems.selectedItem,
          quantity: newOrder.quantity,
          variation: newOrder.variation,
          properties: newOrder.properties,
          subTotal: tempPrice,
        };
        setOrderDetails({
          ...orderDetails,
          items: [...orderDetails.items, tempOrderItem],
        });
        setShowVariation(false);
        setShowCheckout(false);
        setShowCart(true);
      } else {
        toast.error(`${_t(t("Stock Out"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    } else {
      let tempPrice = 0;
      //check variation change hoise
      if (newOrder.variation !== null) {
        tempPrice =
          parseFloat(foodItems.selectedItem.price) +
          parseFloat(newOrder.variation.food_with_variation_price);
      } else {
        tempPrice = parseFloat(foodItems.selectedItem.price);
      }

      // check properties
      if (newOrder.properties !== null && newOrder.properties.length > 0) {
        let tempPropertyPrice = 0;
        newOrder.properties.map((propertyItem, propertyItemIndex) => {
          tempPropertyPrice =
            tempPropertyPrice +
            parseFloat(propertyItem.extra_price) * propertyItem.quantity;
        });
        tempPrice = tempPrice + tempPropertyPrice;
      }

      let tempOrderItem = {
        item: foodItems.selectedItem,
        quantity: newOrder.quantity,
        variation: newOrder.variation,
        properties: newOrder.properties,
        subTotal: tempPrice,
      };

      setOrderDetails({
        ...orderDetails,
        items: [...orderDetails.items, tempOrderItem],
      });
      setShowVariation(false);
      setShowCheckout(false);
      setShowCart(true);
    }
  };

  // addtocartItems
  // const addtocartItems = (e) => {
  //   e.preventDefault();
  //   alert("Hey!! I am cool!!!");
  // }

  //stock
  const handleGetStock = (id) => {
    if (orderDetails.branch === null) {
      return 0;
    }
    let stock = foodStockWeb.find((item) => {
      return (
        parseInt(item.food_id) === parseInt(id) &&
        parseInt(item.branch_id) === parseInt(orderDetails.branch)
      );
    });
    if (stock === undefined || stock.qty < 0) {
      return 0;
    }
    return stock.qty;
  };

  //order quantity
  const handleQty = (cartItemIndex, action) => {
    let oldItems = [];
    orderDetails.items.map((orderItem, orderItemIndex) => {
      if (orderItemIndex !== cartItemIndex) {
        oldItems.push(orderItem);
      } else {
        if (action === "+") {
          let temp = orderItem;

          // check manage stock enable
          if (showManageStock) {
            let stock = handleGetStock(temp.item.id);
            if (stock > handleAlreadyOrderedQty(temp.item.id)) {
              temp.quantity = temp.quantity + 1;
            } else {
              toast.error(`${_t(t("Reached Stock Limit"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            }
          } else {
            temp.quantity = temp.quantity + 1;
          }

          let tempPrice = 0;
          //if no variation
          if (newOrder.variation !== null) {
            tempPrice =
              parseFloat(foodItems.selectedItem.price) +
              parseFloat(newOrder.variation.food_with_variation_price);
          } else {
            tempPrice = parseFloat(foodItems.selectedItem.price);
          }

          if (newOrder.properties !== null && newOrder.properties.length > 0) {
            let tempPropertyPrice = 0;
            newOrder.properties.map((propertyItem, propertyItemIndex) => {
              tempPropertyPrice =
                tempPropertyPrice +
                parseFloat(propertyItem.extra_price) * propertyItem.quantity;
            });
            tempPrice = tempPrice + tempPropertyPrice;
          }

          temp.subTotal = tempPrice * temp.quantity;
          oldItems.push(temp);
        } else {
          let temp = orderItem;
          if (temp.quantity > 1) {
            temp.quantity = temp.quantity - 1;
            let tempPrice = 0;
            //if no variation
            if (newOrder.variation !== null) {
              tempPrice =
                parseFloat(foodItems.selectedItem.price) +
                parseFloat(newOrder.variation.food_with_variation_price);
            } else {
              tempPrice = parseFloat(foodItems.selectedItem.price);
            }

            if (
              newOrder.properties !== null &&
              newOrder.properties.length > 0
            ) {
              let tempPropertyPrice = 0;
              newOrder.properties.map((propertyItem, propertyItemIndex) => {
                tempPropertyPrice =
                  tempPropertyPrice +
                  parseFloat(propertyItem.extra_price) * propertyItem.quantity;
              });
              tempPrice = tempPrice + tempPropertyPrice;
            }

            temp.subTotal = tempPrice * temp.quantity;
            oldItems.push(temp);
          }
        }
      }
    });
    setOrderDetails({ ...orderDetails, items: oldItems });
  };

  //calculate total
  const getTotal = () => {
    let total = 0;
    if (orderDetails.items.length > 0) {
      orderDetails.items.map((temp) => {
        total += temp.subTotal;
      });
    }
    return total;
  };

  //calculate vat
  const getVat = () => {
    let vat = 0;
    let rate = parseFloat(getSystemSettings(generalSettings, "type_vat"));
    vat = (getTotal() * rate) / 100;
    return vat;
  };
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleAddToCart = (foodItem) => {
    if (checkLoginUser === 0) {
      setFoodItems({
        ...foodItems,
        selectedItem: foodItem,
        variations:
          parseInt(foodItem.has_variation) === 1 ? foodItem.variations : null,
        properties:
          parseInt(foodItem.has_property) === 1 ? foodItem.properties : null,
      });
      setNewOrder({
        variation: null,
        quantity: 1,
        properties: null,
      });
      setShowVariation(true);
    } else {
      toast.error(`${_t(t("Please login first"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };
  //handle changes
  const handleChange = (e) => {
    e.preventDefault();
    setOrderDetails({
      ...orderDetails,
      [e.target.name]: e.target.value,
    });
  };
  const [postcode, setPostcode] = useState("");
  const [error, setError] = useState("");
  // stripe payment states
  const validatePostcode = (postcode) => {
    const postcodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}$/i; // Example regex for UK postcodes
    return postcodeRegex.test(postcode);
  };
  const handleFindFood = () => {
    if (validatePostcode(postcode)) {
      setError("");
      // Proceed with finding food
      let branchesPostCode = branchForSearch.map((item) => item.post_code);
      if (!branchesPostCode.includes(postcode)) {
        setError("NO RESTAURANTS NEAR THIS LOCATION");
      } else {
        // add delivery charge here
        debugger;
        let nearBranch = branchForSearch.filter(
          (item) => item.post_code == postcode
        );
        branchForSearch &&
          branchForSearch.filter((item) => {
            if (item.id == nearBranch[0].id) {
              setBranchDeliveryCharge(item.delivery_fee);
            }
          });

        //new order
        setNewOrder({
          variation: null,
          quantity: 1,
          properties: null,
        });

        let theWorkPeriod =
          workPeriodWeb &&
          workPeriodWeb.find((thisItem) => {
            return parseInt(thisItem.branch_id) === parseInt(nearBranch[0].id);
          });
        if (theWorkPeriod !== undefined) {
          setOrderDetails({
            ...orderDetails,
            items: [],
            branch: nearBranch[0].id,
            workPeriod: theWorkPeriod,
            workPeriodStatus: false,
          });
        } else {
          setOrderDetails({
            ...orderDetails,
            items: [],
            branch: null,
            workPeriod: null,
            workPeriodStatus: true,
          });
        }

        setUserPostCode(postcode);
      }
    } else {
      setError("Invalid postcode");
    }
  };
  const [stripeDetails, setstripeDetails] = useState({
    stripe_card_name: null,
    stripe_card_number: null,
    stripe_card_cvc: null,
    stripe_card_exp_month: null,
    stripe_card_exp_year: null,
    stripe_zip_code: null,
  });

  //submit order cod
  const handleOrderSubmit = (e) => {
    setOrderDetails({
      ...orderDetails,
      uploading: true,
    });
    e.preventDefault();
    let url = BASE_URL + "/website/order";
    let formData = orderDetails;
    formData.subTotal = getTotal();
    formData.vat = getVat();
    formData.delivery_charge = parseFloat(branchDeliveryCharge);

    // console.log('check form data',formData);
    return axios
      .post(url, orderDetails, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data !== "ended") {
          setShowCheckout(false);
          setFoodStockWeb(res.data);
          setOrderDetails({
            items: [],
            branch: null,
            workPeriod: null,
            workPeriodStatus: false,
            address: authUserInfo.details && authUserInfo.details.address,
            name: authUserInfo.details && authUserInfo.details.name,
            phn_no: authUserInfo.details && authUserInfo.details.phn_no,
            note: null,
            payment_type: "COD",
            uploading: false,
          });
          toast.success(`${_t(t("Your order has been placed"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else {
          toast.error(`${_t(t("Sorry, this branch is closed now"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          setOrderDetails({
            ...orderDetails,
            uploading: false,
          });
        }
      })
      .catch((err) => {
        setOrderDetails({
          ...orderDetails,
          uploading: false,
        });
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  // handle stripe on change
  const onchangehandleStripePayment = (e) => {
    setstripeDetails({ ...stripeDetails, [e.target.name]: e.target.value });
  };

  // stripe handle submit
  const onsubmithandleStripePayment = (e) => {
    e.preventDefault();
    document.getElementById("stripePaynow").innerHTML = "Please wait...";
    let url = BASE_URL + "/settings/get-stripe-data";

    let formdata = stripeDetails;
    formdata.subTotal = getTotal();
    formdata.vat = getVat();
    formdata.delivery_charge = parseFloat(branchDeliveryCharge);
    return axios
      .post(url, formdata, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        // make reuseble payment component
        if (res.data.status == 422) {
          document.getElementById("stripePaynow").innerHTML = "Pay Now";
          toast.error(`${_t(t("Please enter valid info"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else {
          const payment_id = res.data.card.id;
          const payment_type = "STRIPE";

          // send data to serve start
          setOrderDetails({
            ...orderDetails,
            uploading: true,
          });
          e.preventDefault();
          let url = BASE_URL + "/website/order";
          let formData = orderDetails;
          formData.subTotal = getTotal();
          formData.vat = getVat();
          formData.payment_type = payment_type;
          formData.payment_id = payment_id;
          formData.delivery_charge = parseFloat(branchDeliveryCharge);
          return axios
            .post(url, orderDetails, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              if (res.data !== "ended") {
                setShowCheckout(false);
                setFoodStockWeb(res.data);
                setOrderDetails({
                  items: [],
                  branch: null,
                  workPeriod: null,
                  workPeriodStatus: false,
                  address: authUserInfo.details && authUserInfo.details.address,
                  name: authUserInfo.details && authUserInfo.details.name,
                  phn_no: authUserInfo.details && authUserInfo.details.phn_no,
                  note: null,
                  payment_type: "COD",
                  uploading: false,
                });
                toast.success(`${_t(t("Your order has been placed"))}`, {
                  position: "bottom-center",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                });
              } else {
                toast.error(`${_t(t("Sorry, this branch is closed now"))}`, {
                  position: "bottom-center",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                });
                setOrderDetails({
                  ...orderDetails,
                  uploading: false,
                });
              }
              document.getElementById("stripePaynow").innerHTML = "Pay Now";
            })
            .catch((err) => {
              setOrderDetails({
                ...orderDetails,
                uploading: false,
              });
              toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
              document.getElementById("stripePaynow").innerHTML = "Pay Now";
            });
          // send data to serve end
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //set default language on site load
  const handleOnLoadDefaultLang = () => {
    let localLang = localStorage.i18nextLng;
    if (localLang) {
      if (localLang === undefined || localLang.includes("en-")) {
        navLanguageList &&
          navLanguageList.map((item) => {
            if (item.is_default === true) {
              i18n.changeLanguage(item.code);
              setDefaultLang(item);
            }
            return true;
          });
      } else {
        const temp =
          navLanguageList &&
          navLanguageList.find((item) => {
            return item.code === localLang;
          });
        setDefaultLang(temp);
        i18n.changeLanguage(localLang);
      }
    }
  };

  //change language to selected
  const handleDefaultLang = (e) => {
    let lang =
      navLanguageList &&
      navLanguageList.find((theItem) => {
        return theItem.id === parseInt(e.target.value);
      });
    i18n.changeLanguage(lang.code);
    setDefaultLang(lang);
    toast.success(`${_t(t("Language has been switched!"))}`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
  };

  //set default currency on site load
  const handleOnLoadDefaultCurrency = () => {
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    if (localCurrency === null) {
      navCurrencyList &&
        navCurrencyList.map((item) => {
          if (item.is_default === true) {
            setDefaultCurrency(item);
            localStorage.setItem("currency", JSON.stringify(item));
          }
          return true;
        });
    } else {
      const temp =
        navCurrencyList &&
        navCurrencyList.find((item) => {
          return item.code === localCurrency.code;
        });
      setDefaultCurrency(temp);
    }
  };

  //change currency to selected
  const handleDefaultCurrency = (e) => {
    let item =
      navCurrencyList &&
      navCurrencyList.find((theItem) => {
        return theItem.id === parseInt(e.target.value);
      });
    localStorage.setItem("currency", JSON.stringify(item));
    setDefaultCurrency(item);
    toast.success(`${_t(t("Currency has been changed!"))}`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
  };

  // let flutterPublishKey = "FLWPUBK_TEST-a844c48bd0b956ad1de73ebfe67c3331-X";

  // flutterWaveConfig
  const flutterConfig = {
    public_key: flutterwaveDetails.publicKey,
    tx_ref: Date.now(),
    // amount: 1,
    amount: formatPrice(getTotal() + getVat()),
    // currency: "KES",
    currency: flutterwaveDetails.fw_currency_code,
    payment_options: "card,mobilemoney,ussd,banktransfer",
    customer: {
      email: authUserInfo.details && authUserInfo.details.email,
      phonenumber: orderDetails && orderDetails.phn_no,
      name: orderDetails && orderDetails.name,
    },
    customizations: {
      title: generalSettings && getSystemSettings(generalSettings, "siteName"),
      description: "Payment",
      logo: getSystemSettings(generalSettings, "type_logo"),
    },
    meta: {
      user_id: authUserInfo.details && authUserInfo.details.id,
      // order_id: 8484,
      // product_id: 80707,
    },
    // redirect_url: `${BASE_URL}`,
    // redirect_url: "http://localhost:3000/",
  };

  // handleFlutterwave
  const handleFlutterwave = useFlutterwave(flutterConfig);

  //get selected branch
  const getSelectedBranch = (id) => {
    if (orderDetails && orderDetails.branch !== null) {
      if (id === orderDetails.branch.id) {
        return true;
      }
    }
    return false;
  };

  //paypal integration
  const initialOptions = {
    // "client-id": `${paypal_client_id}`,
    "client-id":
      "AWOafqislzl8zx6-w5BwIOu9p-7DXKNt3Ly4hGzXYNRYBKJkY_yrUcAYSc5RP6YFz_ckikuYoDoBs9NK",
    currency: "USD",
    intent: "capture",
  };

  // flutterwave payment store
  const handleMakeFlutterwavePayment = (response) => {
    if (response.status === "successful") {
      // order
      setOrderDetails({
        ...orderDetails,
        uploading: true,
      });

      let url = BASE_URL + "/website/order";
      let formData = orderDetails;
      formData.subTotal = getTotal();
      formData.vat = getVat();
      formData.payment_type = "FLUTTERWAVE";
      formData.payment_id = response.transaction_id;

      return axios
        .post(url, orderDetails, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if (res.data !== "ended") {
            setShowCheckout(false);
            setFoodStockWeb(res.data);
            setOrderDetails({
              items: [],
              branch: null,
              workPeriod: null,
              workPeriodStatus: false,
              address: authUserInfo.details && authUserInfo.details.address,
              name: authUserInfo.details && authUserInfo.details.name,
              phn_no: authUserInfo.details && authUserInfo.details.phn_no,
              note: null,
              payment_type: "COD",
              uploading: false,
            });
            toast.success(`${_t(t("Your order has been placed"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
            history.push("/");
          } else {
            toast.error(`${_t(t("Sorry, this branch is closed now"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
            setOrderDetails({
              ...orderDetails,
              uploading: false,
            });
          }
        })
        .catch((err) => {
          setOrderDetails({
            ...orderDetails,
            uploading: false,
          });
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    } else {
      toast.error(`${_t(t("Please try again"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  const FindFoodButton = styled(Button)(({ theme }) => ({}));

  const SocialIcons = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
    textAlign: "center",
    "& a": {
      color: "#fff",
      margin: theme.spacing(1),
      textDecoration: "none",
    },
  }));
  const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
      console.log("toggleOpen");
      setIsOpen(!isOpen);
    };

    return (
      <div className="faq-item">
        <div className="faq-question" onClick={toggleOpen}>
          <span className="text-uppercase">{question}</span>
          <span>{isOpen ? "Close -" : "Open +"}</span>
        </div>
        {isOpen && <div className="faq-answer">{answer}</div>}
      </div>
    );
  };
  const faqData = [
    {
      question: "FOOD INFORMATION",
      answer: (
        <ul>
          <li>All our food is 100% halal</li>
          <li>
            All meat and poultry is sourced from British and Irish farms meeting
            set standards of food safety, animal welfare, and environmental
            protection
          </li>
          <li>
            As all our meat and poultry is sourced from UK and Irish farms, they
            are delivered to us fresh and never frozen
          </li>
          <li>
            To give you the best taste, all our grill items are marinated for 24
            hours using our special recipe
          </li>
          <li>
            Our marinade contains no artificial preservatives or colorants and
            no artificial flavours, giving it a totally natural and healthy
            taste
          </li>
          <li>
            All our burgers and steaks are grilled to order resulting in reduced
            fat content
          </li>
          <li>We use minimal amounts of salt in all our recipes</li>
        </ul>
      ),
    },
    {
      question: "DO YOU TAKE BOOKINGS?",
      answer:
        "We love a good party, as do our customers! All of our restaurants are unique, and as such they have different booking policies. If you would like to find out if your local MyLahore takes bookings we recommend contacting them directly and speaking to the manager on duty.",
    },
    {
      question: "HOW CAN I GET A JOB AT café RAJAS?",
      answer:
        "We’re always looking for fun loving people who share our passion for Café Rajas, to join our team click here to fill out an application.",
    },
    {
      question: "How far do you deliver?",
      answer:
        "Although we can appreciate your nearest Café Rajas may not be on your doorstep unfortunately we can only deliver within a 3 mile radius –this enables us to deliver piping hot food on time every time… or you can do what one of our loyal customers did and move closer to us.",
    },
    {
      question: "What is your policy on refunds?",
      answer:
        "We endeavour to get your order right first time every time, to our exceptionally high standards. On the rare occasion we don’t we will look at rectifying the situation amicably. As every situation is different, the manager will use his/her discretion and judgment to resolve the problem.",
    },
    {
      question: "Do you have baby changing facilities?",
      answer: "Yes but just in our restaurant.",
    },
    {
      question: "Do you take payments over the phone?",
      answer:
        "No / you can order online to pay online via credit/debit card (using a non-mobile device).",
    },
    {
      question: "Do you take card payment in branches?",
      answer: "Yes, all branches accept card payments.",
    },
    {
      question: "Do your prices include VAT?",
      answer: "All prices are inclusive of VAT.",
    },
  ];
  return (
    <>
      <NewHeader
        totalOrders={orderDetails?.items?.length || 0}
        cookies={getCookie()}
        authUserInfo={authUserInfo}
        deleteCookie={deleteCookie}
      />
      <div className="container faq-container">
        <h2>FAQs</h2>
        {faqData.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
      <NewFooter />
    </>
  );
};

export default FAQs;
