import React from 'react';

const Templatetwo = () => {
    return (
        <div>
            <h2>this is template two</h2>
        </div>
    );
}

export default Templatetwo;
