import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Paper, Button } from "@mui/material";
//importing context consumer here
import { UserContext } from "../../../contexts/User";
import { FoodContext } from "../../../contexts/Food";
import { RestaurantContext } from "../../../contexts/Restaurant";
import { SettingsContext } from "../../../contexts/Settings";
import cafelogo from "../../../assets/images/cafelogo.png";
import mapIcon from "../../../assets/images/mapIcon.png";
import phoneIcon from "../../../assets/images/icon _phone.png";
import cart from "../../../assets/images/cart.png";
import reserve_online from "../../../assets/images/reserve-online.png";
import recent_deal_1 from "../../../assets/images/recent_deal_1.png";
import recent_deal_2 from "../../../assets/images/recent_deal_2.png";
import crunchy from "../../../assets/images/crunchy.png";
import biryani from "../../../assets/images/biryani.png";
import creamy from "../../../assets/images/creamy.png";
import android_playstore from "../../../assets/images/android-playstore.png";
import iphone_playstore from "../../../assets/images/iphone-playstore.png";
// import MenuAccordion from "./MenuAccordion";
//axios and base url
import Accordion from "@mui/material/Accordion";
import CircularProgress from "@mui/material/CircularProgress";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import axios from "axios";
import { BASE_URL, WEBSITE_URL } from "../../../BaseUrl";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";

import "./onlineorder.css";
import "./card.css";
import "./menuaccordion.css";
//functions
import {
  _t,
  modalLoading,
  restaurantMenuLink,
  getSystemSettings,
  getCookie,
  deleteCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
} from "../../../functions/Functions";

//3rd party packages
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//jQuery initialization
import $ from "jquery";
import { useFlutterwave } from "react-flutterwave";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import NewHeader from "./component/NewHeader";
import NewFooter from "./component/NewFooter";
import PaymentComponent from "./component/PaymentComponent";
import LoginForm from "./LoginForm";
const DessertsOnlineOrder = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  //getting context values here
  let {
    navLanguageList,
    navCurrencyList,
    generalSettings,
    showManageStock,
    paypal_client_id,
  } = useContext(SettingsContext);
  //auth user
  const { authUserInfo } = useContext(UserContext);
  //restaurant
  let { branchForSearch, getBranch } = useContext(RestaurantContext);
  //food
  let {
    getFoodWeb,
    foodListWeb,
    foodGroupWeb,
    propertyGroupWeb,
    workPeriodWeb,
    foodStockWeb,
    branches,
    getBranchesWeb,
    setFoodStockWeb,
  } = useContext(FoodContext);
  // this is comment
  //use state
  const [loading, setLoading] = useState(true);
  const [showCart, setShowCart] = useState(false);
  const [expanded, setExpanded] = useState("panel1");
  const [showCheckout, setShowCheckout] = useState(false);
  const [showVariation, setShowVariation] = useState(false);
  const [value, setValue] = React.useState(1);
  // use showPromotion
  const [showPromotion, setshowPromotion] = useState("NO");

  // stripe
  const [showStripe, setshowStripe] = useState("");

  // flutterwave details
  const [flutterwaveDetails, setflutterwaveDetails] = useState({
    publicKey: null,
    secretKey: null,
    fw_currency_code: null,
    show: "NO",
  });

  // paypal
  const [showPaypal, setshowPaypal] = useState("");

  // branch delivery charge
  const [branchDeliveryCharge, setBranchDeliveryCharge] = useState(0);

  // active branch
  let ActiveBranch =
    branchForSearch && branchForSearch.filter((branch) => branch.active == 1);

  const [deliverymenShow, setdeliverymenShow] = useState(false);
  const [checkLoginUser, setCheckLoginUser] = useState(0);
  //
  const [defaultLang, setDefaultLang] = useState(null);
  const [defaultCurrency, setDefaultCurrency] = useState(null);

  //food and group
  const [foodItems, setFoodItems] = useState({
    list: null, //default on render
    group: null, //default on render
    selectedItem: null, //on select
    variations: null,
    properties: null,
  });
  const [foodItemsList, setFoodItemsList] = useState({
    list: null, //default on render
    group: null, //default on render
    selectedItem: null, //on select
    variations: null,
    properties: null,
  });
  const [loginModal, setLoginModal] = useState(false);
  //new order
  const [newOrder, setNewOrder] = useState({
    variation: null,
    quantity: 1,
    properties: null,
  });

  //order details
  const [orderDetails, setOrderDetails] = useState({
    items: [],
    branch: null, //on select branch
    workPeriod: null, // on select branch
    workPeriodStatus: false,
    address: null, //null get on render user info
    name: null, //get on render
    phn_no: null, //get on render
    note: null,
    payment_type: "COD",
    uploading: false,
  });

  //get sliders values
  const [allSliders, setallSliders] = useState(null);
  const [activeBranch, setactiveBranch] = useState(null);
  const [userPostCode, setUserPostCode] = useState(null);

  // check auth user
  const checkLoginfunc = () => {
    getCookie() !== undefined ? setCheckLoginUser(0) : setCheckLoginUser(1);
  };

  // show stripe
  const showstripebtn = (e) => {
    setLoading(true);
    const url = BASE_URL + `/settings/show-stripe-btn`;
    return axios.get(url).then((res) => {
      setshowStripe(res.data);
      setLoading(false);
    });
  };
  const handleRadioBtnChange = (step) => {
    setValue(step);
  };
  // get flutterwave info
  const getFlutterwaveInfo = () => {
    setLoading(true);
    const url = BASE_URL + `/settings/get-flutterwave-info`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setflutterwaveDetails({
          ...flutterwaveDetails,
          publicKey: res.data.FLUTTERWAVE_PUBLIC_KEY,
          secretKey: res.data.FLUTTERWAVE_SECRET_KEY,
          fw_currency_code: res.data.FLUTTERWAVE_CURRENCY_CODE,
          show: res.data.FLUTTERWAVE_SHOW,
        });

        setLoading(false);
      });
  };

  // show paypal
  const showPaypalBtn = (e) => {
    setLoading(true);

    const url = BASE_URL + `/settings/show-paypal-btn`;
    return axios.get(url).then((res) => {
      setshowStripe(res.data);
      setLoading(false);
    });
  };

  // slider settings
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    className: "",
    // responsive: [
    //     {
    //         breakpoint: 1024,
    //         settings: {
    //             slidesToShow: 3,

    //         }
    //     },
    //     {
    //         breakpoint: 600,
    //         settings: {
    //             slidesToShow: 1,

    //         }
    //     }

    // ]
  };

  //   get all sliders item
  const getSliders = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-hero-sliders";
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallSliders(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  //useeffect == componentDidMount()
  useEffect(() => {
    if (!foodItemsList.length > 0) {
      handleJquery();
      getFoodWeb();
      // getBranch();
      getBranchesWeb();
      getSliders();
      deliveryMenu();
      handleOnLoadDefaultLang();
      handleOnLoadDefaultCurrency();
      checkLoginfunc();
      // showstripebtn();
      setOrderDetails({
        ...orderDetails,
        address:
          (authUserInfo.details && authUserInfo.details.address) ||
          localStorage.getItem("selectedAddress"),
        name: authUserInfo.details && authUserInfo.details.name,
        phn_no: authUserInfo.details && authUserInfo.details.phn_no,
      });
      if (foodGroupWeb && foodListWeb) {
        // let temp = foodListWeb.filter((foodItem, foodItemIndex) => {
        //   return parseInt(foodItem.food_group_id) === foodGroupWeb[0].id;
        // });
        let temp = [];
        for (let i = 0; i < foodGroupWeb.length; i++) {
          temp.push({ ...foodGroupWeb[i], child: [] });
        }

        foodListWeb.forEach((foodItem) => {
          const groupId = parseInt(foodItem.food_group_id);
          for (let i = 0; i < temp.length; i++) {
            if (temp[i].id === groupId) {
              temp[i].child.push(foodItem);
              break;
            }
          }
        });

        const dessertsFood =
          Array.isArray(temp) && temp.length > 0
            ? temp?.filter((item) => item?.type?.toLowerCase() == "desserts")
            : [];
        setExpanded(dessertsFood[0].id);
        setFoodItemsList(dessertsFood);
      }
      setTimeout(() => {
        setLoading(false);
      }, 2500);
    }
  }, [authUserInfo, navCurrencyList, foodListWeb]);

  // useeffect for stripe payment
  useEffect(() => {
    showstripebtn();
    getFlutterwaveInfo();
  }, []);
  useEffect(() => {
    if (localStorage.getItem("n_b") && localStorage.getItem("user_p_c")) {
      // add delivery charge here
      let nearBranch = JSON.parse(localStorage.getItem("n_b"));

      setLoadingFood(false);
      setBranchDeliveryCharge(nearBranch.delivery_fee);

      //new order
      setNewOrder({
        variation: null,
        quantity: 1,
        properties: null,
      });

      let theWorkPeriod =
        workPeriodWeb &&
        workPeriodWeb.find((thisItem) => {
          return parseInt(thisItem.branch_id) === parseInt(nearBranch?.id);
        });
      if (theWorkPeriod !== undefined) {
        setOrderDetails({
          ...orderDetails,
          items: [],
          branch: nearBranch?.id,
          workPeriod: theWorkPeriod,
          workPeriodStatus: false,
          address: localStorage.getItem("selectedAddress"),
        });
      } else {
        setOrderDetails({
          ...orderDetails,
          items: [],
          address: localStorage.getItem("selectedAddress"),
          branch: null,
          workPeriod: null,
          workPeriodStatus: true,
        });
      }
      if (!localStorage.getItem("selectedAddress")) {
        getAddresses(localStorage.getItem("user_p_c"));
      }
      setUserPostCode(localStorage.getItem("user_p_c"));
      if (localStorage.getItem("selectedAddress")) {
        setAddress(localStorage.getItem("selectedAddress"));
        setValue(4);
      } else {
        setValue(2);
      }
    }
  }, []);
  // deliveryman menu update
  const deliveryMenu = () => {
    const url = BASE_URL + `/settings/deliverymen-menu-info`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data.length == 0 || res.data[0].value == 1) {
          setdeliverymenShow(true);
        } else {
          setdeliverymenShow(false);
        }
      });
  };
  //handle jQuery
  const handleJquery = () => {
    $(window).on("scroll", function () {
      var toTopVisible = $("html").scrollTop();
      if (toTopVisible > 500) {
        $(".scrollup").fadeIn();
      } else {
        $(".scrollup").fadeOut();
      }
    });

    // MouseHover Animation home 1
    var hoverLayer = $(".banner-area");
    var heroImgOne = $(".p-shape-1");
    var heroImgTwo = $(".p-shape-2");
    var heroImgThree = $(".p-shape-3");
    var heroImgFour = $(".p-shape-4");
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 100;
      var valueY = (e.pageY * -1) / 120;
      heroImgOne.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 60;
      var valueY = (e.pageY * -1) / 80;
      heroImgTwo.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 40;
      var valueY = (e.pageY * -1) / 60;
      heroImgThree.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 80;
      var valueY = (e.pageY * -1) / 100;
      heroImgFour.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });

    // MouseHover Animation home 2
    var hoverLayer2 = $(".burger-promo-area");
    var heroImgfive = $(".bs1");
    var heroImgsix = $(".bs2");
    var heroImgseven = $(".bs5");
    var heroImgeight = $(".bs6");
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 100;
      var valueY = (e.pageY * -1) / 120;
      heroImgfive.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 60;
      var valueY = (e.pageY * -1) / 80;
      heroImgsix.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 40;
      var valueY = (e.pageY * -1) / 60;
      heroImgseven.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer2.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 80;
      var valueY = (e.pageY * -1) / 100;
      heroImgeight.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });

    // MouseHover Animation home 3
    var hoverLayer3 = $(".snack-section");
    var heroImgnine = $(".ss1");
    var heroImgten = $(".ss2");
    var heroImgeleven = $(".ss3");
    var heroImgtweleve = $(".mss2");
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 100;
      var valueY = (e.pageY * -1) / 120;
      heroImgtweleve.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 60;
      var valueY = (e.pageY * -1) / 80;
      heroImgnine.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 40;
      var valueY = (e.pageY * -1) / 60;
      heroImgten.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer3.mousemove(function (e) {
      var valueX = (e.pageX * -1) / 80;
      var valueY = (e.pageY * -1) / 100;
      heroImgeleven.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
  };

  //dynamic style
  const style = {
    logo: {
      backgroundImage:
        generalSettings &&
        `url(${getSystemSettings(generalSettings, "type_logo")})`,
    },
    currency: {
      backgroundColor:
        generalSettings && getSystemSettings(generalSettings, "type_clock"),
      color:
        generalSettings && getSystemSettings(generalSettings, "type_color"),
    },
  };

  //logout

  //orders variation
  const handleOrderItemVariation = (item) => {
    setNewOrder({
      ...newOrder,
      variation: item,
    });
  };

  //property
  const handleOrderItemProperty = (propertyItem) => {
    let newTemp = [];
    if (newOrder.properties !== null && newOrder.properties.length > 0) {
      let checkExist = newOrder.properties.find((exist) => {
        return exist.id === propertyItem.id;
      });
      if (checkExist === undefined) {
        newOrder.properties.map((oldItem) => {
          newTemp.push(oldItem);
        });
        propertyItem.quantity = 1;
        newTemp.push(propertyItem);
      } else {
        newOrder.properties.map((oldItem) => {
          if (oldItem.id !== propertyItem.id) {
            newTemp.push(oldItem);
          }
        });
      }
      setNewOrder({
        ...newOrder,
        properties: newTemp,
      });
    } else {
      propertyItem.quantity = 1;
      setNewOrder({
        ...newOrder,
        properties: [propertyItem],
      });
    }
  };

  //multiple qty
  const checkedProperty = (eachItem) => {
    if (newOrder.properties !== null) {
      let findChecked = newOrder.properties.find((checkIt) => {
        return checkIt.id === eachItem.id;
      });
      if (findChecked === undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  //property checked
  const checkCheckedPropertyQuantity = (propertyItem) => {
    if (newOrder.properties !== null) {
      let theItem = newOrder.properties.find((findThis) => {
        return findThis.id === propertyItem.id;
      });
      if (theItem !== undefined) {
        return theItem.quantity;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  };

  //set propertyqty
  const handlePropertyQty = (propertyItem, action) => {
    let newTemp = [];
    if (newOrder.properties !== null && newOrder.properties.length > 0) {
      newOrder.properties.map((pushThis) => {
        if (pushThis.id === propertyItem.id) {
          if (action === "+") {
            pushThis.quantity = pushThis.quantity + 1;
            newTemp.push(pushThis);
          } else {
            if (pushThis.quantity > 1) {
              pushThis.quantity = pushThis.quantity - 1;
            }
            newTemp.push(pushThis);
          }
        } else {
          newTemp.push(pushThis);
        }
      });
      setNewOrder({
        ...newOrder,
        properties: newTemp,
      });
    }
  };

  //get already ordered qty
  const handleAlreadyOrderedQty = (id) => {
    let temp = 0;
    if (orderDetails.items.length > 0) {
      orderDetails.items.map((item) => {
        if (parseInt(item.item.id) === id) {
          temp += parseInt(item.quantity);
        }
      });
    }
    return temp;
  };

  //add to cart
  const handleOrder = () => {
    // check if manage stock is enable
    if (showManageStock) {
      if (
        // handleGetStock(foodItems.selectedItem.id) >
        // handleAlreadyOrderedQty(foodItems.selectedItem.id)
        foodItems.selectedItem?.stocks?.status === false //new condition for ingredient checking
      ) {
        let tempPrice = 0;
        //check variation change hoise
        if (newOrder.variation !== null) {
          tempPrice = parseFloat(newOrder.variation.food_with_variation_price);
          // +parseFloat(foodItems.selectedItem.price);
        } else {
          tempPrice = parseFloat(foodItems.selectedItem.price);
        }

        if (newOrder.properties !== null && newOrder.properties.length > 0) {
          let tempPropertyPrice = 0;
          newOrder.properties.map((propertyItem, propertyItemIndex) => {
            tempPropertyPrice =
              tempPropertyPrice +
              parseFloat(propertyItem.extra_price) * propertyItem.quantity;
          });
          tempPrice = tempPrice + tempPropertyPrice;
        }

        let tempOrderItem = {
          item: foodItems.selectedItem,
          quantity: newOrder.quantity,
          variation: newOrder.variation,
          properties: newOrder.properties,
          subTotal: tempPrice,
        };
        let alredyExists = orderDetails.items.some(
          (item) =>
            item.item.id == tempOrderItem.item.id &&
            item.variation == tempOrderItem.variation
        );
        orderDetails.items.map((item) => {
          if (
            item.item.id == tempOrderItem.item.id &&
            item.variation == tempOrderItem.variation
          ) {
            item.quantity += 1;
            item.subTotal += item.subTotal;
          }
          return item;
        });
        if (alredyExists) {
          setOrderDetails({
            ...orderDetails,
            items: [...orderDetails.items],
          });
        } else {
          setOrderDetails({
            ...orderDetails,
            items: [...orderDetails.items, tempOrderItem],
          });
        }
        setShowVariation(false);
        setShowCheckout(false);
        setShowCart(true);
      } else {
        toast.error(`${_t(t("Stock Out"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    } else {
      let tempPrice = 0;
      //check variation change hoise
      if (newOrder.variation !== null) {
        tempPrice =
          parseFloat(foodItems.selectedItem.price) +
          parseFloat(newOrder.variation.food_with_variation_price);
      } else {
        tempPrice = parseFloat(foodItems.selectedItem.price);
      }

      // check properties
      if (newOrder.properties !== null && newOrder.properties.length > 0) {
        let tempPropertyPrice = 0;
        newOrder.properties.map((propertyItem, propertyItemIndex) => {
          tempPropertyPrice =
            tempPropertyPrice +
            parseFloat(propertyItem.extra_price) * propertyItem.quantity;
        });
        tempPrice = tempPrice + tempPropertyPrice;
      }

      let tempOrderItem = {
        item: foodItems.selectedItem,
        quantity: newOrder.quantity,
        variation: newOrder.variation,
        properties: newOrder.properties,
        subTotal: tempPrice,
      };

      setOrderDetails({
        ...orderDetails,
        items: [...orderDetails.items, tempOrderItem],
      });
      setShowVariation(false);
      setShowCheckout(false);
      setShowCart(true);
    }
  };

  // addtocartItems
  // const addtocartItems = (e) => {
  //   e.preventDefault();
  //   alert("Hey!! I am cool!!!");
  // }

  //stock
  const handleGetStock = (id) => {
    if (orderDetails.branch === null) {
      return 0;
    }
    let stock = foodStockWeb.find((item) => {
      return (
        parseInt(item.food_id) === parseInt(id) &&
        parseInt(item.branch_id) === parseInt(orderDetails.branch)
      );
    });
    if (stock === undefined || stock.qty < 0) {
      return 0;
    }
    return stock.qty;
  };

  //order quantity
  const handleQty = (cartItemIndex, action) => {
    let oldItems = [];
    orderDetails.items.map((orderItem, orderItemIndex) => {
      if (orderItemIndex !== cartItemIndex) {
        oldItems.push(orderItem);
      } else {
        if (action === "+") {
          let temp = orderItem;

          // check manage stock enable
          if (showManageStock) {
            let stock = handleGetStock(temp.item.id);
            // if (stock > handleAlreadyOrderedQty(temp.item.id)) {
            temp.quantity = temp.quantity + 1;
            // } else {
            //   toast.error(`${_t(t("Reached Stock Limit"))}`, {
            //     position: "bottom-center",
            //     autoClose: 10000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     className: "text-center toast-notification",
            //   });
            // }
          } else {
            temp.quantity = temp.quantity + 1;
          }

          let tempPrice = 0;
          //if no variation
          if (newOrder.variation !== null) {
            tempPrice =
              parseFloat(foodItems.selectedItem.price) +
              parseFloat(newOrder.variation.food_with_variation_price);
          } else {
            tempPrice = parseFloat(foodItems.selectedItem.price);
          }

          if (newOrder.properties !== null && newOrder.properties.length > 0) {
            let tempPropertyPrice = 0;
            newOrder.properties.map((propertyItem, propertyItemIndex) => {
              tempPropertyPrice =
                tempPropertyPrice +
                parseFloat(propertyItem.extra_price) * propertyItem.quantity;
            });
            tempPrice = tempPrice + tempPropertyPrice;
          }

          temp.subTotal = tempPrice * temp.quantity;
          oldItems.push(temp);
        } else {
          let temp = orderItem;
          if (temp.quantity > 1) {
            temp.quantity = temp.quantity - 1;
            let tempPrice = 0;
            //if no variation
            if (newOrder.variation !== null) {
              tempPrice =
                parseFloat(foodItems.selectedItem.price) +
                parseFloat(newOrder.variation.food_with_variation_price);
            } else {
              tempPrice = parseFloat(foodItems.selectedItem.price);
            }

            if (
              newOrder.properties !== null &&
              newOrder.properties.length > 0
            ) {
              let tempPropertyPrice = 0;
              newOrder.properties.map((propertyItem, propertyItemIndex) => {
                tempPropertyPrice =
                  tempPropertyPrice +
                  parseFloat(propertyItem.extra_price) * propertyItem.quantity;
              });
              tempPrice = tempPrice + tempPropertyPrice;
            }

            temp.subTotal = tempPrice * temp.quantity;
            oldItems.push(temp);
          }
        }
      }
    });
    setOrderDetails({ ...orderDetails, items: oldItems });
  };
  const handleLoginModal = () => {
    setLoginModal(true);
    setShowCart(false);
    setShowCheckout(false);
    setShowVariation(false);
  };
  //calculate total
  const getTotal = () => {
    let total = 0;
    if (orderDetails.items.length > 0) {
      orderDetails.items.map((temp) => {
        total += temp.subTotal;
      });
    }
    return total;
  };

  //calculate vat
  const getVat = () => {
    let vat = 0;
    let rate = parseFloat(getSystemSettings(generalSettings, "type_vat"));
    vat = (getTotal() * rate) / 100;
    return vat;
  };
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAddToCart = (foodItem) => {
    setFoodItems({
      ...foodItems,
      selectedItem: foodItem,
      variations:
        parseInt(foodItem.has_variation) === 1 ? foodItem.variations : null,
      properties:
        parseInt(foodItem.has_property) === 1 ? foodItem.properties : null,
    });
    setNewOrder({
      variation: null,
      quantity: 1,
      properties: null,
    });
    setShowVariation(true);
  };
  //handle changes
  const handleChange = (e) => {
    e.preventDefault();
    setOrderDetails({
      ...orderDetails,
      [e.target.name]: e.target.value,
    });
  };
  const [postcode, setPostcode] = useState("");
  const [userAddress, setUserAddress] = useState({});
  const [error, setError] = useState("");
  const [loadingFood, setLoadingFood] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [addressLoader, setAddressLoader] = useState(false);
  const [address, setAddress] = useState("");
  // stripe payment states
  const validatePostcode = (postcode) => {
    const postcodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}$/i; // Example regex for UK postcodes
    return postcodeRegex.test(postcode);
  };
  const getAddresses = async (user_code) => {
    // check expiry if no then can not access  else continue
    setAddressLoader(true);
    const url = BASE_URL + "/website/get_postcode_address";
    const api_data = { postcode: user_code ? user_code : postcode };
    await axios
      .post(url, api_data)
      .then((res) => {
        if (res.status == 200) {
          // add delivery charge here
          setSelectedAddress(res?.data?.suggestions || []);
          setAddressLoader(false);
        }
      })
      .catch((err) => {
        setAddressLoader(false);
      });
  };
  const handleFindFood = () => {
    if (validatePostcode(postcode)) {
      setLoadingFood(true);
      setError("");
      // check expiry if no then can not access  else continue
      const url = BASE_URL + "/website/check_delivery_area";
      const api_data = { postcode };
      axios
        .post(url, api_data)
        .then((res) => {
          if (res.status == 200) {
            // add delivery charge here
            let nearBranch = res.data.branch;
            localStorage.setItem("n_b", JSON.stringify(nearBranch));
            setLoadingFood(false);
            setBranchDeliveryCharge(nearBranch.delivery_fee);

            //new order
            setNewOrder({
              variation: null,
              quantity: 1,
              properties: null,
            });

            let theWorkPeriod =
              workPeriodWeb &&
              workPeriodWeb.find((thisItem) => {
                return (
                  parseInt(thisItem.branch_id) === parseInt(nearBranch?.id)
                );
              });
            if (theWorkPeriod !== undefined) {
              setOrderDetails({
                ...orderDetails,
                items: [],
                branch: nearBranch?.id,
                workPeriod: theWorkPeriod,
                workPeriodStatus: false,
              });
            } else {
              setOrderDetails({
                ...orderDetails,
                items: [],
                branch: null,
                workPeriod: null,
                workPeriodStatus: true,
              });
            }
            setValue(2);
            getAddresses();
            localStorage.setItem("user_p_c", postcode);
            setUserPostCode(postcode);
          }
        })
        .catch((err) => {
          setLoadingFood(false);
          toast.error(err.response.data.message, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    } else {
      setError("Invalid postcode");
    }
  };
  const [stripeDetails, setstripeDetails] = useState({
    stripe_card_name: null,
    stripe_card_number: null,
    stripe_card_cvc: null,
    stripe_card_exp_month: null,
    stripe_card_exp_year: null,
    stripe_zip_code: null,
  });

  //submit order cod
  const handleOrderSubmit = (e) => {
    setOrderDetails({
      ...orderDetails,
      uploading: true,
    });
    e.preventDefault();
    let url = BASE_URL + "/website/order";
    let formData = orderDetails;
    formData.subTotal = getTotal();
    formData.vat = getVat();
    formData.delivery_charge = parseFloat(branchDeliveryCharge);

    return axios
      .post(url, orderDetails, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data !== "ended") {
          setShowCheckout(false);
          setFoodStockWeb(res.data);
          setOrderDetails({
            items: [],
            branch: null,
            workPeriod: null,
            workPeriodStatus: false,
            address: authUserInfo.details && authUserInfo.details.address,
            name: authUserInfo.details && authUserInfo.details.name,
            phn_no: authUserInfo.details && authUserInfo.details.phn_no,
            note: null,
            payment_type: "COD",
            uploading: false,
          });
          toast.success(`${_t(t("Your order has been placed"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else {
          toast.error(`${_t(t("Sorry, this branch is closed now"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          setOrderDetails({
            ...orderDetails,
            uploading: false,
          });
        }
      })
      .catch((err) => {
        setOrderDetails({
          ...orderDetails,
          uploading: false,
        });
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  // handle stripe on change
  const onchangehandleStripePayment = (e) => {
    setstripeDetails({ ...stripeDetails, [e.target.name]: e.target.value });
  };

  // stripe handle submit
  const getStripeToken = (card_token, stripe_details, setLoading) => {
    let url = BASE_URL + "/settings/get-stripe-data";

    let formdata = stripe_details;
    formdata.subTotal = getTotal();
    formdata.vat = getVat();
    formdata.delivery_charge = parseFloat(branchDeliveryCharge);
    formdata.token_id = card_token.id;
    return axios
      .post(url, formdata, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        // make reuseble payment component
        if (res.data.status == 422 || res.status == 422) {
          toast.error(`${_t(t("Please enter valid info"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else {
          const payment_id = card_token.card.id;
          const payment_type = "STRIPE";

          // send data to serve start
          setOrderDetails({
            ...orderDetails,
            uploading: true,
          });
          let url = BASE_URL + "/website/order";
          let formData = orderDetails;
          formData.subTotal = getTotal();
          formData.vat = getVat();
          formData.payment_type = payment_type;
          formData.payment_id = payment_id;
          formData.delivery_charge = parseFloat(branchDeliveryCharge);
          return axios
            .post(url, orderDetails, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              if (res.data !== "ended") {
                setShowCheckout(false);
                setFoodStockWeb(res.data);
                setOrderDetails({
                  items: [],
                  branch: null,
                  workPeriod: null,
                  workPeriodStatus: false,
                  address: authUserInfo.details && authUserInfo.details.address,
                  name: authUserInfo.details && authUserInfo.details.name,
                  phn_no: authUserInfo.details && authUserInfo.details.phn_no,
                  note: null,
                  payment_type: "COD",
                  uploading: false,
                });
                toast.success(`${_t(t("Your order has been placed"))}`, {
                  position: "bottom-center",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                });
                setLoading(false);

                setTimeout(() => {
                  window.location.href = window.location.origin + "/";
                }, 2000);
              } else {
                toast.error(`${_t(t("Sorry, this branch is closed now"))}`, {
                  position: "bottom-center",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                });
                setOrderDetails({
                  ...orderDetails,
                  uploading: false,
                });
              }
            })
            .catch((err) => {
              setOrderDetails({
                ...orderDetails,
                uploading: false,
              });
              toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            });
          // send data to serve end
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //set default language on site load
  const handleOnLoadDefaultLang = () => {
    let localLang = localStorage.i18nextLng;
    if (localLang) {
      if (localLang === undefined || localLang.includes("en-")) {
        navLanguageList &&
          navLanguageList.map((item) => {
            if (item.is_default === true) {
              i18n.changeLanguage(item.code);
              setDefaultLang(item);
            }
            return true;
          });
      } else {
        const temp =
          navLanguageList &&
          navLanguageList.find((item) => {
            return item.code === localLang;
          });
        setDefaultLang(temp);
        i18n.changeLanguage(localLang);
      }
    }
  };

  //change language to selected
  const handleDefaultLang = (e) => {
    let lang =
      navLanguageList &&
      navLanguageList.find((theItem) => {
        return theItem.id === parseInt(e.target.value);
      });
    i18n.changeLanguage(lang.code);
    setDefaultLang(lang);
    toast.success(`${_t(t("Language has been switched!"))}`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
  };

  //set default currency on site load
  const handleOnLoadDefaultCurrency = () => {
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    if (localCurrency === null) {
      navCurrencyList &&
        navCurrencyList.map((item) => {
          if (item.is_default === true) {
            setDefaultCurrency(item);
            localStorage.setItem("currency", JSON.stringify(item));
          }
          return true;
        });
    } else {
      const temp =
        navCurrencyList &&
        navCurrencyList.find((item) => {
          return item.code === localCurrency.code;
        });
      setDefaultCurrency(temp);
    }
  };

  //change currency to selected
  const handleDefaultCurrency = (e) => {
    let item =
      navCurrencyList &&
      navCurrencyList.find((theItem) => {
        return theItem.id === parseInt(e.target.value);
      });
    localStorage.setItem("currency", JSON.stringify(item));
    setDefaultCurrency(item);
    toast.success(`${_t(t("Currency has been changed!"))}`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
  };
  // let flutterPublishKey = "FLWPUBK_TEST-a844c48bd0b956ad1de73ebfe67c3331-X";

  // flutterWaveConfig
  const flutterConfig = {
    public_key: flutterwaveDetails.publicKey,
    tx_ref: Date.now(),
    // amount: 1,
    amount: formatPrice(getTotal() + getVat()),
    // currency: "KES",
    currency: flutterwaveDetails.fw_currency_code,
    payment_options: "card,mobilemoney,ussd,banktransfer",
    customer: {
      email: authUserInfo.details && authUserInfo.details.email,
      phonenumber: orderDetails && orderDetails.phn_no,
      name: orderDetails && orderDetails.name,
    },
    customizations: {
      title: generalSettings && getSystemSettings(generalSettings, "siteName"),
      description: "Payment",
      logo: getSystemSettings(generalSettings, "type_logo"),
    },
    meta: {
      user_id: authUserInfo.details && authUserInfo.details.id,
      // order_id: 8484,
      // product_id: 80707,
    },
    // redirect_url: `${BASE_URL}`,
    // redirect_url: "http://localhost:3000/",
  };

  // handleFlutterwave
  const handleFlutterwave = useFlutterwave(flutterConfig);

  //get selected branch
  const getSelectedBranch = (id) => {
    if (orderDetails && orderDetails.branch !== null) {
      if (id === orderDetails.branch.id) {
        return true;
      }
    }
    return false;
  };

  //paypal integration
  const initialOptions = {
    // "client-id": `${paypal_client_id}`,
    "client-id":
      "AWOafqislzl8zx6-w5BwIOu9p-7DXKNt3Ly4hGzXYNRYBKJkY_yrUcAYSc5RP6YFz_ckikuYoDoBs9NK",
    currency: "USD",
    intent: "capture",
  };

  // flutterwave payment store
  const handleMakeFlutterwavePayment = (response) => {
    if (response.status === "successful") {
      // order
      setOrderDetails({
        ...orderDetails,
        uploading: true,
      });

      let url = BASE_URL + "/website/order";
      let formData = orderDetails;
      formData.subTotal = getTotal();
      formData.vat = getVat();
      formData.payment_type = "FLUTTERWAVE";
      formData.payment_id = response.transaction_id;

      return axios
        .post(url, orderDetails, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if (res.data !== "ended") {
            setShowCheckout(false);
            setFoodStockWeb(res.data);
            setOrderDetails({
              items: [],
              branch: null,
              workPeriod: null,
              workPeriodStatus: false,
              address: authUserInfo.details && authUserInfo.details.address,
              name: authUserInfo.details && authUserInfo.details.name,
              phn_no: authUserInfo.details && authUserInfo.details.phn_no,
              note: null,
              payment_type: "COD",
              uploading: false,
            });
            toast.success(`${_t(t("Your order has been placed"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
            history.push("/");
          } else {
            toast.error(`${_t(t("Sorry, this branch is closed now"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
            setOrderDetails({
              ...orderDetails,
              uploading: false,
            });
          }
        })
        .catch((err) => {
          setOrderDetails({
            ...orderDetails,
            uploading: false,
          });
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    } else {
      toast.error(`${_t(t("Please try again"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  const FindFoodButton = styled(Button)(({ theme }) => ({}));

  const SocialIcons = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
    textAlign: "center",
    "& a": {
      color: "#fff",
      margin: theme.spacing(1),
      textDecoration: "none",
    },
  }));
  const handleAddressSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = {};
    formData.forEach((value, key) => (data[key] = value));
    if (selectedAddress?.length > 0) {
      setUserAddress(data);
    } else {
      let addre_ss = `${data.street},${data.line2},${data.city},${data.country},${data.postcode}`;
      setAddress(addre_ss);
      localStorage.setItem("selectedAddress", addre_ss);
    }
    setValue(4);
  };
  const handleAddressSelect = (e) => {
    const selected_Address = e.target.value;
    const addressData = selected_Address.split(",");
    let data = {
      street: addressData[0] || "",
      line2: addressData[1] || "",
      city: addressData[2] || "",
      country: addressData[addressData.length - 1 == 3 ? 2 : 3] || "",
      postcode: addressData[addressData.length - 1] || "",
    };
    if (selected_Address) {
      setAddress(selected_Address);
      localStorage.setItem("selected_Address", selected_Address);
      setOrderDetails({
        ...orderDetails,
        address: selected_Address,
      });
      document.getElementById("street").value = data.street;
      document.getElementById("line2").value = data.line2;
      document.getElementById("city").value = data.city;
      document.getElementById("country").value = data.country;
      document.getElementById("postcode").value = data.postcode;
    } else {
      // Clear the fields if no address is selected
      document.getElementById("street").value = "";
      document.getElementById("line2").value = "";
      document.getElementById("city").value = "";
      document.getElementById("country").value = "";
      document.getElementById("postcode").value = "";
    }
  };
  return (
    <>
      {value == 1 ? (
        <>
          <Box className="formContainer">
            <div>
              <NavLink to="/" exact key="logokey">
                <img
                  src={cafelogo}
                  style={{ width: "15rem", height: "10rem" }}
                />
              </NavLink>
            </div>
            <p className="postcode-text">
              ENTER POSTCODE FOR DELIVERY OF FOOD IN <span>3 SIMPLE STEPS</span>
            </p>
            <TextField
              variant="outlined"
              placeholder="My postcode"
              fullWidth
              value={postcode}
              disableUnderline
              onChange={(e) => {
                setError("");
                setPostcode(e.target.value);
              }}
              className="address-field1"
            />
            {error && <p className="helperText">{error}</p>}
            <Button
              className="find-food-btn"
              variant="contained"
              onClick={handleFindFood}
              disabled={loadingFood}
              fullWidth
            >
              {loadingFood ? (
                <CircularProgress style={{ width: "30px", height: "30px" }} />
              ) : (
                "Find food"
              )}
            </Button>
          </Box>
          <SocialIcons>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f" style={{ fontSize: "20px" }}></i>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter" style={{ fontSize: "20px" }}></i>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram" style={{ fontSize: "20px" }}></i>
            </a>
            {/* <a href="#" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-pinterest"></i>
            </a> */}
          </SocialIcons>
        </>
      ) : value == 2 ? (
        <>
          <Box className="formContainer">
            <div>
              <NavLink to="/" exact key="logokey">
                <img
                  src={cafelogo}
                  style={{ width: "15rem", height: "10rem" }}
                />
              </NavLink>
            </div>
            <div className="container">
              <div className="row">
                <div class="form-group form-group-text col-xs-12 col-sm-12 text-center">
                  <p className="postcode-text">Please place your order</p>
                </div>
                <div class="form-group form-group-text col-xs-12 col-sm-6 text-center">
                  <Button
                    className="find-food-btn"
                    variant="contained"
                    onClick={() => handleRadioBtnChange(4)}
                    disabled={loadingFood}
                    fullWidth
                  >
                    Click & Collect
                  </Button>
                </div>
                <div class="form-group form-group-text col-xs-12 col-sm-6 text-center">
                  <Button
                    className="find-food-btn"
                    variant="contained"
                    onClick={() => handleRadioBtnChange(3)}
                    disabled={loadingFood}
                    fullWidth
                  >
                    Delivery
                  </Button>
                </div>
              </div>
            </div>
          </Box>
          <SocialIcons>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f" style={{ fontSize: "20px" }}></i>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter" style={{ fontSize: "20px" }}></i>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram" style={{ fontSize: "20px" }}></i>
            </a>
            {/* <a href="#" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-pinterest"></i>
          </a> */}
          </SocialIcons>
        </>
      ) : value == 3 ? (
        <>
          <Box className="formContainer">
            <div>
              <NavLink to="/" exact key="logokey">
                <img
                  src={cafelogo}
                  style={{ width: "15rem", height: "10rem" }}
                />
              </NavLink>
            </div>
            <p className="postcode-text">I'd like delivery to my address</p>
            <div class="form-container">
              {!addressLoader ? (
                <form id="addressForm" onSubmit={handleAddressSubmit}>
                  <div className="container">
                    <div className="row">
                      <div class="form-group col-xs-12">
                        <select
                          id="addressSelect"
                          onChange={handleAddressSelect}
                          className="address-field"
                        >
                          <option value="">Select your address...</option>
                          {selectedAddress?.map((item, index) => {
                            return (
                              <option value={item.address} key={index}>
                                {item.address}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div class="form-group col-xs-12">
                        <input
                          type="text"
                          id="street"
                          className="address-field"
                          name="street"
                          placeholder="Street address"
                          required
                        />
                      </div>
                      <div class="form-group col-xs-12">
                        <input
                          type="text"
                          id="line2"
                          name="line2"
                          className="address-field"
                          placeholder="2nd line of address"
                        />
                      </div>
                      <div class="form-group col-xs-12">
                        <input
                          type="text"
                          id="city"
                          name="city"
                          className="address-field"
                          placeholder="City"
                          required
                        />
                      </div>
                      <div class="form-group col-xs-12">
                        <input
                          type="text"
                          id="country"
                          className="address-field"
                          name="country"
                          placeholder="Country"
                        />
                      </div>
                      <div class="form-group col-xs-12">
                        <input
                          type="text"
                          id="postcode"
                          name="postcode"
                          className="address-field"
                          placeholder="Postcode"
                          required
                        />
                      </div>

                      <Button
                        className="find-food-btn"
                        variant="contained"
                        type="submit"
                        fullWidth
                      >
                        continue
                      </Button>
                    </div>
                  </div>
                </form>
              ) : (
                <div class="spinner-border text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              )}
            </div>
          </Box>
          <SocialIcons>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f" style={{ fontSize: "20px" }}></i>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter" style={{ fontSize: "20px" }}></i>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram" style={{ fontSize: "20px" }}></i>
            </a>
            {/* <a href="#" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-pinterest"></i>
            </a> */}
          </SocialIcons>
        </>
      ) : (
        <>
          {/* paypal modal */}
          <div
            className="modal fade"
            id="exampleModalCenter"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title" id="exampleModalLongTitle">
                    pay with paypal
                  </h6>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <PayPalScriptProvider options={initialOptions}>
                    <PayPalButtons
                      forceReRender={[orderDetails]}
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: formatPrice(
                                  getTotal() + getVat() + branchDeliveryCharge
                                ),
                              },
                            },
                          ],
                        });
                      }}
                      onApprove={(data, actions) => {
                        return actions.order
                          .capture()
                          .then(function (orderData) {
                            var transaction_id =
                              orderData.purchase_units[0].payments.captures[0]
                                .id;
                            // order
                            setOrderDetails({
                              ...orderDetails,
                              uploading: true,
                            });

                            let url = BASE_URL + "/website/order";
                            let formData = orderDetails;
                            formData.subTotal = getTotal();
                            formData.vat = getVat();
                            formData.payment_type = "PAYPAL";
                            formData.payment_id = transaction_id;
                            return axios
                              .post(url, orderDetails, {
                                headers: {
                                  Authorization: `Bearer ${getCookie()}`,
                                },
                              })
                              .then((res) => {
                                if (res.data !== "ended") {
                                  setShowCheckout(false);
                                  setFoodStockWeb(res.data);
                                  setOrderDetails({
                                    items: [],
                                    branch: null,
                                    workPeriod: null,
                                    workPeriodStatus: false,
                                    address:
                                      authUserInfo.details &&
                                      authUserInfo.details.address,
                                    name:
                                      authUserInfo.details &&
                                      authUserInfo.details.name,
                                    phn_no:
                                      authUserInfo.details &&
                                      authUserInfo.details.phn_no,
                                    note: null,
                                    payment_type: "COD",
                                    uploading: false,
                                  });
                                  toast.success(
                                    `${_t(t("Your order has been placed"))}`,
                                    {
                                      position: "bottom-center",
                                      autoClose: 10000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      className:
                                        "text-center toast-notification",
                                    }
                                  );
                                  history.push("/");
                                } else {
                                  toast.error(
                                    `${_t(
                                      t("Sorry, this branch is closed now")
                                    )}`,
                                    {
                                      position: "bottom-center",
                                      autoClose: 10000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      className:
                                        "text-center toast-notification",
                                    }
                                  );
                                  setOrderDetails({
                                    ...orderDetails,
                                    uploading: false,
                                  });
                                }
                              })
                              .catch((err) => {
                                setOrderDetails({
                                  ...orderDetails,
                                  uploading: false,
                                });
                                toast.error(`${_t(t("Please try again"))}`, {
                                  position: "bottom-center",
                                  autoClose: 10000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  className: "text-center toast-notification",
                                });
                              });
                            // end here
                          });
                      }}
                    />
                  </PayPalScriptProvider>
                </div>
              </div>
            </div>
          </div>
          {/* paypal modal */}

          {/* strip modal */}
          <div
            className="modal fade"
            id="exampleModalCenterStrip"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <PaymentComponent getToken={getStripeToken} />
              </div>
            </div>
          </div>
          {/* strip modal */}
          <div
            className={`modal fade ${loginModal ? "show" : ""}`}
            id="loginFormModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden={loginModal ? "false" : "true"}
            style={{
              display: loginModal ? "block" : "none",
              opacity: loginModal ? 1 : 0,
            }}
            onClick={() => setLoginModal(false)}
          >
            <div
              className="modal-dialog modal-dialog-centered"
              role="document"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-content p-3">
                <LoginForm
                  navigateTo="/desserts"
                  setLoginModal={setLoginModal}
                  checkLoginfunc={checkLoginfunc}
                />
              </div>
            </div>
          </div>
          <Helmet>
            <title>
              {generalSettings &&
                getSystemSettings(generalSettings, "siteName")}
            </title>
            <link rel="stylesheet" href="/website/css/animate.css" />
            <link rel="stylesheet" href="/website/css/meanmenu.min.css" />
            <link rel="stylesheet" href="./website/css/bootstrap.min.css" />
            <link
              rel="stylesheet"
              href="/website/css/font-awsome-all.min.css"
            />
            <link rel="stylesheet" href="/website/css/magnific-popup.css" />
            <link rel="stylesheet" href="/website/css/slick.css" />
            <link rel="stylesheet" href="/website/css/jquery-ui.css" />
            <link rel="stylesheet" href="/website/css/style.css" />

            <script src="/website/js/vendor/jquery-2.2.4.min.js"></script>

            <script src="/website/js/vendor/bootstrap.min.js"></script>
            <script src="./website/js/vendor/jquery.meanmenu.min.js"></script>
            <script src="/website/js/vendor/jquery.magnific-popup.min.js"></script>
            <script src="/website/js/vendor/slick.min.js"></script>
            <script src="/website/js/vendor/counterup.min.js"></script>
            <script src="/website/js/vendor/countdown.js"></script>
            <script src="/website/js/vendor/waypoints.min.js"></script>
            <script src="/website/js/vendor/jquery-ui.js"></script>
            <script src="/website/js/vendor/isotope.pkgd.min.js"></script>
            <script src="/website/js/vendor/easing.min.js"></script>
            <script src="/website/js/vendor/wow.min.js"></script>
            <script src="/website/js/simplebar.js"></script>
            <script src="/website/js/main.js"></script>
          </Helmet>

          {/* <!-- Preloader Starts --> */}
          <div
            className={`preloader02 ${!loading && "d-none"}`}
            id="preloader02"
          >
            <div className="preloader-inner">
              <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            </div>
          </div>

          {/* Floating Cart button*/}
          <div
            className={`kh-floating-cart pointer-cursor ${loading && "d-none"}`}
            onClick={() => {
              setShowCart(true);
            }}
          >
            <div className="kh-floating-cart__container">
              <span className="kh-floating-cart__text text-capitalize pb-2">
                <i className="fas fa-shopping-cart"></i>{" "}
                {orderDetails.items ? orderDetails.items.length : 0}{" "}
                {_t(t("item"))}
              </span>
              <span className="kh-floating-cart__money text-uppercase py-2 text-center">
                {formatPrice(
                  getTotal() + getVat() + parseFloat(branchDeliveryCharge)
                )}
                {currencySymbolRight()}
              </span>
            </div>
          </div>
          {/* Floating Cart End */}

          {/* cart drawer */}
          {showCart && (
            <div className={`kh-drawer`}>
              <div className="kh-drawer__container">
                <div className="kh-drawer__head pyyy-3 my-3 d-flex align-items-center justify-content-between">
                  <span className="kh-drawer__head-title text-capitalize">
                    {getSystemSettings(generalSettings, "siteName")}
                  </span>
                  <button
                    type="button"
                    className="kh-drawer__close"
                    onClick={() => {
                      setShowCart(false);
                      setShowCheckout(false);
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                <div className="kh-drawer__body pyyy-3" data-simplebar>
                  <div className="kh-drawer__container">
                    <ul
                      className="kh-drawer__list"
                      style={{ minHeight: "300px" }}
                    >
                      {orderDetails.items && orderDetails.items.length > 0 ? (
                        orderDetails.items.map((cartItem, cartItemIndex) => {
                          // eikhane kaj
                          return (
                            <li
                              className="kh-drawer__list-item border-bottom"
                              key={cartItemIndex}
                            >
                              <div className="kh-drawer__product py-1 d-flex justify-content-between align-items-center">
                                <a
                                  href="#"
                                  className="kh-drawer__product-title text-capitalize pointer-cursor"
                                >
                                  {cartItem.item.name}
                                </a>
                                <span className="kh-drawer__product-price text-uppercase text-right">
                                  {currencySymbolLeft()}
                                  {formatPrice(cartItem.subTotal)}
                                  {currencySymbolRight()}
                                </span>
                              </div>

                              {/* if variation exiest */}
                              <div className="kh-drawer__product d-flex justify-content-between ">
                                {cartItem.variation && (
                                  <a
                                    href="#"
                                    className="kh-drawer__product-subtitle text-capitalize pointer-cursor"
                                  >
                                    {cartItem.variation && (
                                      <span className="d-block">
                                        + {cartItem.variation.variation_name}
                                      </span>
                                    )}
                                  </a>
                                )}
                              </div>

                              {/* if properties exiest */}
                              <div className="kh-drawer__product d-flex justify-content-between ">
                                {cartItem.properties &&
                                  cartItem.properties.length > 0 && (
                                    <a
                                      href="#"
                                      className="kh-drawer__product-subtitle text-capitalize pointer-cursor"
                                    >
                                      {cartItem.properties.map(
                                        (eachProperty) => {
                                          return (
                                            <span className="d-block">
                                              + {eachProperty.name}
                                              {eachProperty.quantity > 1
                                                ? "(" +
                                                  eachProperty.quantity +
                                                  ")"
                                                : ""}
                                            </span>
                                          );
                                        }
                                      )}
                                    </a>
                                  )}
                                {!cartItem.properties && (
                                  <a
                                    href="#"
                                    className="kh-drawer__product-subtitle text-capitalize pointer-cursor"
                                  >
                                    <span className="d-block"></span>
                                  </a>
                                )}

                                <div className="kh-drawer__product-quantity d-flex justify-content-between align-items-center">
                                  <button
                                    className="kh-drawer__product-quantity-decrease kh-drawer__product-quantity-btn"
                                    onClick={() => {
                                      handleQty(cartItemIndex, "-");
                                    }}
                                  >
                                    <i
                                      className={`fas ${
                                        cartItem.quantity > 1
                                          ? "fa-minus"
                                          : "fa-trash"
                                      }`}
                                    ></i>
                                  </button>
                                  <div className="kh-drawer__product-quantity-value text-center flex-grow-1">
                                    {cartItem.quantity}
                                  </div>
                                  <button
                                    className="kh-drawer__product-quantity-increase kh-drawer__product-quantity-btn"
                                    onClick={() => {
                                      handleQty(cartItemIndex, "+");
                                    }}
                                  >
                                    <i className="fas fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <div
                          className="text-center"
                          style={{ minHeight: "300px", paddingTop: "100px" }}
                        >
                          {_t(t("No item added"))}
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="kh-drawer__foot pyyy-3 my-3">
                  <div className="kh-drawer__container">
                    <div className="d-flex align-items-center justify-content-between my-2">
                      <div className="kh-drawer__text text-capitalize">
                        {_t(t("subtotal "))}
                      </div>
                      <div className="kh-drawer__text text-right text-uppercase sm-text">
                        {currencySymbolLeft()}
                        {formatPrice(getTotal())}
                        {currencySymbolRight()}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-2">
                      <div className="kh-drawer__text text-capitalize">
                        {getSystemSettings(generalSettings, "vat_system") ===
                        "igst"
                          ? `${_t(t("vat"))}(${getSystemSettings(
                              generalSettings,
                              "type_vat"
                            )}%)`
                          : getSystemSettings(generalSettings, "vat_system") ===
                            "cgst"
                          ? "cgst+sgst" +
                            "(" +
                            getSystemSettings(generalSettings, "cgst") +
                            "%" +
                            "+" +
                            getSystemSettings(generalSettings, "sgst") +
                            "%)"
                          : `${_t(t("tax"))}(${getSystemSettings(
                              generalSettings,
                              "tax"
                            )}%)`}
                      </div>
                      <div className="kh-drawer__text text-right text-uppercase sm-text">
                        {currencySymbolLeft()}
                        {formatPrice(getVat())}
                        {currencySymbolRight()}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-2">
                      <div className="kh-drawer__text text-capitalize">
                        delivery charge
                      </div>
                      <div className="kh-drawer__text text-right text-uppercase sm-text">
                        {currencySymbolLeft()}
                        {formatPrice(parseFloat(branchDeliveryCharge))}
                        {currencySymbolRight()}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-2">
                      <div className="kh-drawer__text text-capitalize">
                        total
                      </div>
                      <div className="kh-drawer__text text-right text-uppercase sm-text">
                        {currencySymbolLeft()}
                        {formatPrice(
                          getTotal() +
                            getVat() +
                            parseFloat(branchDeliveryCharge)
                        )}
                        {currencySymbolRight()}
                      </div>
                    </div>

                    {getCookie() === undefined ? (
                      <button
                        onClick={handleLoginModal}
                        // data-toggle="modal"
                        // data-target="#loginFormModal"
                        className="btn w-100 text-uppercase"
                      >
                        {_t(t("Login"))}
                      </button>
                    ) : (
                      <>
                        {orderDetails.items.length > 0 ? (
                          <button
                            className="btn w-100 text-uppercase"
                            onClick={() => {
                              setShowCart(false);
                              setShowCheckout(true);
                            }}
                          >
                            {_t(t("go to checkout"))}
                          </button>
                        ) : (
                          <button
                            className="btn w-100 text-uppercase"
                            onClick={() => {
                              setShowCart(false);
                              setShowCheckout(true);
                            }}
                            disabled
                          >
                            {_t(t("go to checkout"))}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* cart drawer ends*/}

          {/* Checkout drawer */}
          {showCheckout && (
            <div className="kh-drawer">
              <form onSubmit={handleOrderSubmit}>
                <div className="kh-drawer__container">
                  <div className="kh-drawer__head py-3  d-flex align-items-center justify-content-between">
                    <button
                      type="button"
                      className="kh-drawer__back"
                      onClick={() => {
                        setShowCart(true);
                        setShowCheckout(false);
                      }}
                    >
                      <i className="fas fa-arrow-left"></i>
                    </button>
                    <span className="kh-drawer__head-title text-capitalize">
                      {getSystemSettings(generalSettings, "siteName")}
                    </span>
                    <button
                      type="button"
                      className="kh-drawer__close"
                      onClick={() => {
                        setShowCart(false);
                        setShowCheckout(false);
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                  <div className="kh-drawer__body py-3" data-simplebar>
                    <div className="kh-drawer__container">
                      <ul className="kh-drawer__list">
                        <li className="kh-drawer__list-item">
                          <div className="kh-drawer__details">
                            <div className="kh-drawer__details-head d-flex align-items-center">
                              <div className="kh-drawer__details-count text-center d-flex align-items-center justify-content-center">
                                {_t(t("1"))}
                              </div>
                              <div className="kh-drawer__details-title text-capitalize">
                                {_t(t("Delivery Details"))}
                              </div>
                            </div>
                            <div className="kh-drawer__details-body my-3">
                              <div className="form-group">
                                <label
                                  htmlFor="location"
                                  className="sm-text text-capitalize"
                                >
                                  {_t(t("enter delivery address"))}
                                </label>
                                <textarea
                                  className="form-control sm-text"
                                  rows="3"
                                  placeholder={_t(t("Address"))}
                                  required
                                  value={
                                    address ? address : orderDetails?.address
                                  }
                                  disabled={address}
                                  name="address"
                                  onChange={handleChange}
                                >
                                  {orderDetails.address}
                                </textarea>
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="note"
                                  className="sm-text text-capitalize"
                                >
                                  {_t(t("Note to rider"))}
                                </label>
                                <input
                                  type="text"
                                  className="form-control rounded-0 sm-text"
                                  id="note"
                                  onChange={handleChange}
                                  name="note"
                                  placeholder={_t(t("Note to rider"))}
                                  value={orderDetails.note}
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="kh-drawer__list-item">
                          <div className="kh-drawer__details">
                            <div className="kh-drawer__details-head d-flex align-items-center">
                              <div className="kh-drawer__details-count text-center d-flex align-items-center justify-content-center">
                                {_t(t("2"))}
                              </div>
                              <div className="kh-drawer__details-title text-capitalize">
                                {_t(t("Personal Details"))}
                              </div>
                            </div>
                            <div className="kh-drawer__details-body my-3">
                              <div className="form-group">
                                <label
                                  htmlFor="firstName"
                                  className="sm-text text-capitalize"
                                >
                                  {_t(t("name"))}
                                </label>
                                <input
                                  type="text"
                                  className="form-control rounded-0 sm-text"
                                  id="firstName"
                                  placeholder={_t(t("name"))}
                                  name="name"
                                  disabled
                                  value={orderDetails.name}
                                />
                              </div>

                              <div className="form-group">
                                <label
                                  htmlFor="phone"
                                  className="sm-text text-capitalize"
                                >
                                  {_t(t("mobile number"))}
                                </label>
                                <input
                                  type="text"
                                  className="form-control rounded-0 sm-text"
                                  id="phone"
                                  placeholder={_t(t("mobile number"))}
                                  name="phn_no"
                                  required
                                  onChange={handleChange}
                                  value={orderDetails.phn_no}
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="kh-drawer__foot py-3">
                    <div className="kh-drawer__container">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="kh-drawer__text text-capitalize">
                          {_t(t("subtotal "))}
                        </div>
                        <div className="kh-drawer__text text-right text-uppercase sm-text">
                          {currencySymbolLeft()}
                          {formatPrice(getTotal())}
                          {currencySymbolRight()}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between my-2">
                        <div className="kh-drawer__text text-capitalize">
                          {getSystemSettings(generalSettings, "vat_system") ===
                          "igst"
                            ? `${_t(t("vat"))}(${getSystemSettings(
                                generalSettings,
                                "type_vat"
                              )}%)`
                            : getSystemSettings(
                                generalSettings,
                                "vat_system"
                              ) === "cgst"
                            ? "cgst+sgst" +
                              "(" +
                              getSystemSettings(generalSettings, "cgst") +
                              "%" +
                              "+" +
                              getSystemSettings(generalSettings, "sgst") +
                              "%)"
                            : `${_t(t("tax"))}(${getSystemSettings(
                                generalSettings,
                                "tax"
                              )}%)`}
                        </div>
                        <div className="kh-drawer__text text-right text-uppercase sm-text">
                          {currencySymbolLeft()}
                          {formatPrice(getVat())}
                          {currencySymbolRight()}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between my-2">
                        <div className="kh-drawer__text text-capitalize">
                          delivery charge
                        </div>
                        <div className="kh-drawer__text text-right text-uppercase sm-text">
                          {currencySymbolLeft()}
                          {formatPrice(parseFloat(branchDeliveryCharge))}
                          {currencySymbolRight()}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between my-2">
                        <div className="kh-drawer__text text-capitalize">
                          total
                        </div>
                        <div className="kh-drawer__text text-right text-uppercase sm-text">
                          {currencySymbolLeft()}
                          {formatPrice(
                            getTotal() +
                              getVat() +
                              parseFloat(branchDeliveryCharge)
                          )}
                          {currencySymbolRight()}
                        </div>
                      </div>
                      {getCookie() === undefined ? (
                        <button
                          onClick={handleLoginModal}
                          // data-toggle="modal"
                          // data-target="#loginFormModal"
                          className="btn w-100 text-uppercase"
                        >
                          {_t(t("Login"))}
                        </button>
                      ) : (
                        <>
                          {orderDetails.uploading ? (
                            modalLoading(2)
                          ) : (
                            <>
                              <div
                                data-simplebar
                                className="d-flex align-items-center justify-content-between"
                              >
                                {/* stripe button */}

                                {showStripe == "YES"
                                  ? [
                                      orderDetails.address === null ? (
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                          className="btn-danger payment-btn btn-sm px-4 mr-4 text-capitalize"
                                        >
                                          {_t(t("place order"))}
                                        </button>
                                      ) : (
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setShowCart(false);
                                            setShowCheckout(false);
                                          }}
                                          className="btn-danger payment-btn btn-sm px-4 mr-4 text-capitalize"
                                          data-toggle="modal"
                                          data-target="#exampleModalCenterStrip"
                                        >
                                          {_t(t("place order"))}
                                        </button>
                                      ),
                                    ]
                                  : ""}
                                {/* place order */}
                                {/* <button
                                  type="submit"
                                  className="btn-danger payment-btn btn-sm  px-4 text-capitalize"
                                  disabled={orderDetails.branch === null}
                                >
                                  {_t(t("place order"))}
                                </button> */}

                                {/* flutterwave */}
                                {flutterwaveDetails.show === "YES"
                                  ? [
                                      <button
                                        type="submit"
                                        className="btn-danger payment-btn btn-sm  px-4 text-capitalize ml-4"
                                        disabled={orderDetails.branch === null}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleFlutterwave({
                                            callback: (response) => {
                                              handleMakeFlutterwavePayment(
                                                response
                                              );
                                            },
                                            onClose: () => {},
                                          });
                                        }}
                                      >
                                        {_t(t("flutterwave"))}
                                      </button>,
                                    ]
                                  : ""}

                                {/* activate for paypal */}
                                {/* {paypal_client_id !== null ?
                            [orderDetails.address === null ? <button onClick={(e) => {
                              e.preventDefault();
                            }} className="btn-danger payment-btn btn-sm"
                            >
                              paypal
                            </button> : <button onClick={(e) => {
                              e.preventDefault();
                            }} className="btn-danger payment-btn btn-sm" data-toggle="modal" data-target="#exampleModalCenter"
                            >
                              paypal
                            </button>] : null
                          } */}

                                {/* for static activation */}

                                {/* {
                            orderDetails.address === null ? <button onClick={(e) => {
                              e.preventDefault();
                            }} className="btn-danger payment-btn btn-sm"
                            >
                              paypal
                            </button> : <button onClick={(e) => {
                              e.preventDefault();
                            }} className="btn-danger payment-btn btn-sm" data-toggle="modal" data-target="#exampleModalCenter"
                            >
                              paypal
                            </button>
                          } */}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
          {/* Checkout drawer ends */}

          {/* Variation and property drawer */}
          {showVariation && (
            <div className="kh-drawer">
              <div className="kh-drawer__container">
                <div className="kh-drawer__head py-3 my-3 d-flex align-items-center justify-content-between">
                  <span className="kh-drawer__head-title text-capitalize">
                    {_t(t("Add item to cart"))}
                  </span>
                  <button
                    type="button"
                    className="kh-drawer__close"
                    onClick={() => {
                      setShowVariation(false);
                      setShowCart(false);
                      setShowCheckout(false);
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                <div className="kh-drawer__body_cart py-3" data-simplebar>
                  <div className="kh-drawer__container">
                    {/* old branch select option */}
                    <div className="form-group">
                      <label htmlFor="branch" className="sm-text">
                        {_t(t("Select a branch"))}{" "}
                        {orderDetails.workPeriodStatus === true && (
                          <small className="text-danger">
                            {" "}
                            ( {_t(t("This branch is closed now"))})
                          </small>
                        )}
                      </label>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          // add delivery charge here
                          branchForSearch &&
                            branchForSearch.filter((item) => {
                              if (item.id == e.target.value) {
                                setBranchDeliveryCharge(item.delivery_fee);
                              }
                            });

                          //new order
                          setNewOrder({
                            variation: null,
                            quantity: 1,
                            properties: null,
                          });

                          let theWorkPeriod =
                            workPeriodWeb &&
                            workPeriodWeb.find((thisItem) => {
                              return (
                                parseInt(thisItem.branch_id) ===
                                parseInt(e.target.value)
                              );
                            });
                          if (theWorkPeriod !== undefined) {
                            setOrderDetails({
                              ...orderDetails,
                              items: [],
                              branch: e.target.value,
                              workPeriod: theWorkPeriod,
                              workPeriodStatus: false,
                            });
                          } else {
                            setOrderDetails({
                              ...orderDetails,
                              items: [],
                              branch: null,
                              workPeriod: null,
                              workPeriodStatus: true,
                            });
                          }
                        }}
                      >
                        <option value="">
                          {_t(t("Please select a branch"))}
                        </option>

                        {ActiveBranch?.map((eachBranch) => {
                          return (
                            <option
                              value={eachBranch.id}
                              selected={
                                eachBranch.id === parseInt(orderDetails.branch)
                              }
                            >
                              {eachBranch.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {/* old branch select option */}

                    <ul className="kh-drawer__list">
                      {!foodItems.variations && !foodItems.properties && (
                        <img
                          src={foodItems.selectedItem.image}
                          alt=""
                          className="img-fluid"
                        />
                      )}
                      {/* Variations */}
                      {foodItems.variations && (
                        <>
                          {/* active variation price is completed */}
                          <div className="fk-sm-card__container mb-1 pr-4">
                            <div className="fk-sm-card__content">
                              <h6 className="text-capitalize fk-sm-card__title t-mb-5">
                                {_t(t("Base Price"))}
                              </h6>
                            </div>

                            <span className="text-capitalize">
                              {foodItems.selectedItem.price}
                            </span>
                          </div>
                          <div className="fk-sm-card__container mb-1 pr-4">
                            <div className="fk-sm-card__content">
                              <h6 className="text-capitalize fk-sm-card__title t-mb-5">
                                {_t(t("Variations"))}
                              </h6>
                            </div>

                            {/* <span className="text-capitalize xxsm-text fk-badge fk-badge--secondary">
                        {_t(t("Required"))}
                      </span> */}
                          </div>

                          {foodItems.variations.map(
                            (variationItem, varItemIndex) => {
                              return (
                                <li className="kh-drawer__list-item my-0 py-0">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="col">
                                      <label className="mx-checkbox flex-grow-1">
                                        <input
                                          type="checkbox"
                                          className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                          name="variation"
                                          onChange={() => {
                                            handleOrderItemVariation(
                                              variationItem
                                            );
                                          }}
                                          checked={
                                            newOrder.variation &&
                                            newOrder.variation
                                              .food_with_variation_id ===
                                              variationItem.food_with_variation_id
                                          }
                                        />
                                        <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8">
                                          {variationItem.variation_name}
                                        </span>
                                      </label>
                                    </div>
                                    <div className="col text-right">
                                      <span className="t-text-heading text-uppercase sm-text flex-grow-1">
                                        {currencySymbolLeft()}
                                        {formatPrice(
                                          variationItem.food_with_variation_price
                                        )}
                                        {currencySymbolRight()}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              );
                            }
                          )}

                          {foodItems.properties && <hr />}
                        </>
                      )}

                      {foodItems.properties &&
                        foodItems.properties.map(
                          (propertyItem, propertyItemIndex) => {
                            let selectedGroup = null;
                            //property group
                            if (propertyItem.length > 0) {
                              selectedGroup =
                                propertyGroupWeb &&
                                propertyGroupWeb.find((singlePropertyGroup) => {
                                  return (
                                    singlePropertyGroup.id ===
                                    parseInt(propertyItem[0].property_group_id)
                                  );
                                });
                            }

                            return (
                              <>
                                <div className="fk-sm-card__container mb-1">
                                  <div className="fk-sm-card__content">
                                    <h6 className="text-capitalize fk-sm-card__title t-mb-5">
                                      {selectedGroup && selectedGroup.name}
                                    </h6>
                                  </div>
                                  <span className="text-capitalize xxsm-text fk-badge fk-badge--secondary">
                                    {_t(t("Optional"))}
                                  </span>
                                </div>
                                {propertyItem.map((eachItem) => {
                                  return (
                                    <li className="kh-drawer__list-item my-0 py-0">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="col">
                                          <label className="mx-checkbox flex-grow-1">
                                            <input
                                              type="checkbox"
                                              className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                              onChange={() => {
                                                handleOrderItemProperty(
                                                  eachItem
                                                );
                                              }}
                                              checked={checkedProperty(
                                                eachItem
                                              )}
                                            />
                                            <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8">
                                              {eachItem.name}
                                            </span>
                                          </label>
                                        </div>
                                        <div className="col">
                                          <div className="fk-qty flex-grow-1 justify-content-end">
                                            {parseInt(
                                              eachItem.allow_multi_quantity
                                            ) === 1 && (
                                              <span
                                                className="fk-qty__icon fk-qty__deduct"
                                                onClick={() => {
                                                  handlePropertyQty(
                                                    eachItem,
                                                    "-"
                                                  );
                                                }}
                                              >
                                                <i className="las la-minus"></i>
                                              </span>
                                            )}
                                            {parseInt(
                                              eachItem.allow_multi_quantity
                                            ) === 1 ? (
                                              <input
                                                type="text"
                                                value={checkCheckedPropertyQuantity(
                                                  eachItem
                                                )}
                                                className="fk-qty__input t-bg-clear"
                                                readOnly
                                              />
                                            ) : (
                                              "-"
                                            )}
                                            {parseInt(
                                              eachItem.allow_multi_quantity
                                            ) === 1 && (
                                              <span
                                                className="fk-qty__icon fk-qty__add"
                                                onClick={() => {
                                                  handlePropertyQty(
                                                    eachItem,
                                                    "+"
                                                  );
                                                }}
                                              >
                                                <i className="las la-plus"></i>
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col text-right">
                                          <span className="t-text-heading text-uppercase sm-text flex-grow-1">
                                            {currencySymbolLeft()}
                                            {formatPrice(eachItem.extra_price)}
                                            {currencySymbolRight()}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                                {propertyItemIndex !==
                                  foodItems.properties.length - 1 && <hr />}
                              </>
                            );
                          }
                        )}
                    </ul>
                  </div>
                </div>
                <div className="kh-drawer__foot py-3 my-3">
                  <div className="kh-drawer__container">
                    {getCookie() === undefined ? (
                      <button
                        onClick={handleLoginModal}
                        // data-toggle="modal"
                        // data-target="#loginFormModal"
                        className="btn w-100 text-uppercase"
                      >
                        {_t(t("Login"))}
                      </button>
                    ) : (
                      <>
                        <button
                          className="btn w-100 text-uppercase"
                          disabled={
                            (foodItems.variations && !newOrder.variation) ||
                            orderDetails.branch == null
                          }
                          onClick={handleOrder}
                        >
                          {_t(t("Add to cart"))}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Variation and property drawer ends */}

          {/* template content section */}
          <div className={loading ? "d-none" : ""}>
            <NewHeader
              totalOrders={orderDetails?.items?.length || 0}
              cookies={getCookie()}
              authUserInfo={authUserInfo}
              deleteCookie={deleteCookie}
            />
            <section className="banner-section">
              <div className="container-fluid py-5">
                <div className="row justify-content-evenly align-items-center">
                  <div className="col-sm-12 col-lg-7">
                    <div className="row">
                      <div className="col-sm-12">
                        <p className="flavor-rich-text-2">Flavor Rich</p>
                      </div>
                      <div className="col-sm-12">
                        <p className="served-with-pession-text-2">
                          Served With Passion
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8 col-lg-5">
                    <div className="order-online-image">
                      <NavLink to="/reserve-online" exact key="logokey">
                        <img src={reserve_online} width={100} />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-evenly align-items-center">
                  <div className="col-sm-12 col-lg-12">
                    <div className="container">
                      <div className=" row menu-card-wrapper">
                        <div className="col-sm-12 px-0">
                          <p className="special-deals-text">
                            View Special Deals
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- drink-items section --> */}
            <section className="search-section">
              <div className="container search-container2 margin-top-80">
                <div style={{ position: "relative", width: "100%" }}>
                  <i class="fas fa-search search-icon-2"></i>
                  <input
                    type="text"
                    className="form-control search-icon-input-2"
                    placeholder="Search"
                  />
                </div>
                <div>
                  <p className="food-items-text">Food Items</p>
                </div>
                <div>
                  <p className="popular-menu-text">
                    <span style={{ color: "#FF9A03" }}>Popular </span> Menu{" "}
                  </p>
                </div>
                {foodItemsList.length > 0
                  ? foodItemsList.map((food) => {
                      return (
                        <Accordion
                          expanded={expanded === food.id}
                          onChange={handleAccordionChange(food.id)}
                          sx={{
                            boxShadow: "none",
                            "&::before": {
                              display: "none",
                            },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<div></div>}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ padding: "0px" }}
                          >
                            <div className="accordion-summary">
                              <p
                                className={
                                  expanded == food.id
                                    ? "appetizers-deal-accordion-active"
                                    : "appetizers-deal-accordion"
                                }
                              >
                                {food.name || ""}
                              </p>
                              {/* {expanded !== food.id && (
                                <p className="appetizers-deal-accordion-2">
                                  Click here to Check Out Appetizers Specials
                                </p>
                              )} */}
                            </div>
                          </AccordionSummary>
                          <AccordionDetails style={{ padding: "0px" }}>
                            <div className="container-fluid">
                              <div className="row">
                                {food?.child.length > 0 ? (
                                  food?.child.map((food_child) => {
                                    return (
                                      <div className="col-lg-4 p-1">
                                        <div className="menu-items-card">
                                          <img
                                            src={food_child?.image}
                                            alt="Special Mix Starter"
                                          />
                                          <div className="card-content">
                                            <p className="card-header">
                                              {food_child?.name}
                                            </p>
                                            <p className="menu-items">
                                              ({food_child?.description})
                                            </p>
                                            <div className="card-actions">
                                              <button
                                                className="add-to-card-btn"
                                                onClick={() =>
                                                  handleAddToCart(food_child)
                                                }
                                              >
                                                {_t(t("Add to cart"))}
                                              </button>
                                              <span>£{food_child?.price}</span>
                                              {/* {parseInt(foodItem.has_variation) !== 1 &&
                                  currencySymbolLeft() +
                                    formatPrice(foodItem.price) +
                                    currencySymbolRight()} */}
                                              <i
                                                class="far fa-heart fa-fw heart-icon"
                                                style={{ color: "#000" }}
                                              ></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="text-center py-4">N/A</div>
                                )}
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })
                  : ""}
              </div>
            </section>
            {/* layers section */}
            <section className="container  margin-top-80 ">
              <div className="row">
                <div className="col-12">
                  <img src={crunchy} className="img-fluid" />
                </div>
              </div>
            </section>
            <section className="container  margin-top-80 ">
              <div className="row">
                <div className="col-12">
                  <img src={biryani} className="img-fluid" />
                </div>
              </div>
            </section>{" "}
            <section className="container  margin-top-80 ">
              <div className="row">
                <div className="col-12">
                  <img src={creamy} className="img-fluid" />
                </div>
              </div>
            </section>
            <NewFooter />
            {/* <!-- ToTop Button --> */}
            <button
              className="scrollup"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            >
              <i className="fas fa-angle-up"></i>
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default DessertsOnlineOrder;
