import React from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import cafelogo from "../../../../assets/images/cafelogo.png";
import recent_deal_1 from "../../../../assets/images/recent_deal_1.png";
import recent_deal_2 from "../../../../assets/images/recent_deal_2.png";
import iphone_playstore from "../../../../assets/images/iphone-playstore.png";
import android_playstore from "../../../../assets/images/android-playstore.png";
import samplePDFNew from "../../../../assets/files/Allergen.pdf";

function NewFooter() {
  const handleDownload = () => {
    window.open(samplePDFNew, "_blank");
  };

  return (
    <footer className="margin-top-80">
      <div className="footer-container">
        <div className="row align-items-center">
          <div className="col-sm-12 col-lg-4">
            <div className="row">
              <div className="col-auto">
                <NavLink to="/" exact key="logokey">
                  <img
                    src={cafelogo}
                    alt="logo1"
                    style={{ width: "13rem", height: "10rem" }}
                  />
                </NavLink>
              </div>
              <div className="col-12">
                <p
                  style={{
                    fontSize: "1rem",
                    color: "#fff",
                    fontFamily: "Kite One",
                    lineHeight: "1.5rem",
                    letterSpacing: "1.5px",
                    wordSpacing: "0.5px",
                    zIndex: 0,
                  }}
                >
                  Cafe Rajas invites you on a culinary journey where flavors
                  collide and tastes ignite. From sizzling burgers to cheesy
                  pizzas, our menu boasts an array of delectable fast food
                  delights. Indulge in classics like our Rajas Special Burger or
                  opt for a slice of heaven with our Supreme Pizza. Discover
                  your favorites today!
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-8">
            <div className="row footer-menu-container">
              <div className="col-sm-12 col-md-6 col-lg-auto footer-heading ">
                <div className="footer-header">Menu</div>
                <a href="#" onClick={handleDownload}>
                  Alergan Info
                </a>
                <a href="/onlineorder">Order Now</a>
                <a href="/gallery2">Gallery</a>
                <a href="/feedback">Feedback</a>
                <a href="/faqs">FAQs</a>
                <a href="/career">Career</a>
                <a href="/uncoocked">Uncooked Range</a>
                <a href="/desserts">Dessert Boxes</a>

                {/* <a href="#">Vegeterian</a>
                <a href="#">Seafood</a>
                <a href="#">Desi Chaats</a>
                <a href="#">Boneless Dishes</a>
                <a href="#">Fresh Karahi</a>
                <a href="#">Vegetable Dishes</a>
                <a href="#">Biryani</a>
                <a href="#">Sundries</a> */}
              </div>
              <div className="col-sm-12 col-md-6 col-lg-auto footer-heading ">
                <div className="footer-header">Recent Deals</div>
                <div className="d-flex flex-sm-column flex-md-row flex-lg-column">
                  <img
                    className="mr-2 mb-2"
                    src={recent_deal_1}
                    style={{ width: "6rem", height: "6rem" }}
                  />
                  <img
                    src={recent_deal_2}
                    style={{ width: "6rem", height: "6rem" }}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-auto footer-heading">
                <div className="footer-header">Contact Us</div>
                <p>
                  <i
                    class="fas fa-phone-alt pr-2"
                    style={{ color: "#FEB905" }}
                  ></i>{" "}
                  01274723400
                </p>
                <p className="py-3">
                  {" "}
                  <i
                    class="fas fa-envelope pr-2"
                    style={{ color: "#FEB905" }}
                  ></i>
                  info@caferajas.com
                </p>
                <p>
                  <i class="fas fa-map pr-2" style={{ color: "#FEB905" }}></i>{" "}
                  Bradford, United Kingdom.
                </p>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-auto footer-heading">
                <div>
                  <div className="footer-header">Timing</div>
                  <p>
                    <i
                      class="far fa-clock pr-2"
                      style={{ color: "#FEB905" }}
                    ></i>{" "}
                    05:00AM- 02:00AM
                  </p>
                </div>
                <div className="py-3">
                  <p>
                    <i
                      class="fab fa-facebook-square fa-lg pr-2"
                      style={{ color: "#FEB905" }}
                    ></i>
                    <i
                      class="fab fa-instagram fa-lg pr-2"
                      style={{ color: "#FEB905" }}
                    ></i>
                    <i
                      class="fab fa-facebook-square fa-lg pr-2"
                      style={{ color: "#FEB905" }}
                    ></i>
                    <i
                      class="fab fa-instagram fa-lg pr-2"
                      style={{ color: "#FEB905" }}
                    ></i>
                  </p>
                </div>

                <div className="app-download-btn-container">
                  <button className="app-download-btn">
                    <img src={android_playstore} className="app-download-img" />{" "}
                    <span> Download App</span>
                  </button>
                  <button className="app-download-btn">
                    {" "}
                    <img src={iphone_playstore} className="app-download-img" />
                    <span> Download App</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right-text">
        © Copyright 2024 - All Rights Reserved by Cafe Rajas
      </div>
    </footer>
  );
}

export default NewFooter;
