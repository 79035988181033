import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import {
  BASE_URL,
  SAAS_APPLICATION,
  saas_apiUrl,
  saas_apiParams,
  saas_form_data,
} from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const RecipeBuilder = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, setLoading, dataPaginating } = useContext(SettingsContext);

  let [isDataSending, setisDataSending] = useState(false);

  // title & instructions
  let [recipeItem, setrecipeItem] = useState({
    name: null,
    instructions: null,
  });

  // ingredient
  let [recipeIngredients, setrecipeIngredients] = useState([]);

  let {
    //item
    ingredientallItemList,
    getallIngredientItemWithoutPagination,
  } = useContext(RestaurantContext);

  //post req of food item add
  const handleSubmit = (e) => {
    e.preventDefault();
    setisDataSending(true);

    let formData = new FormData();
    formData.append("des", JSON.stringify(recipeItem));
    formData.append("recipeList", JSON.stringify(recipeIngredients));

    // console.log("submitted data", [recipeItem, recipeIngredients]);
    const url = BASE_URL + "/settings/make-recipe";
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        console.log(res);
        setrecipeItem({
          name: null,
          instructions: null,
        });
        setrecipeIngredients([]);
        setLoading(false);
        toast.success(`${_t(t("Recipe has been added"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch((error) => {
        setisDataSending(false);
        console.log(error);
        toast.error(`${_t(t("Please try again!"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  // handle ingredient items
  const handleIngredientItems = (newItem) => {
    setrecipeIngredients(newItem);
    // setrecipeIngredients([...recipeIngredients,newItem]);
  };

  // previous codes
  // const handleEachIngredientAmount = (e) => {
  //   const selectedIngredient = e.target.name;
  //   const selectedIngredientAmount = e.target.value;

  //   // find the ingredients from array of object
  //   const checkIngredient = recipeIngredients.findIndex(
  //     (ing) => ing.slug === selectedIngredient
  //   );

  //   // copy existing array
  //   const updatedInredients = [...recipeIngredients];

  //   // update the selected ingredinets
  //   updatedInredients[checkIngredient] = {
  //     ...updatedInredients[checkIngredient],
  //     amount_of_ingredient: selectedIngredientAmount,
  //   };

  //   // update with latest data
  //   setrecipeIngredients(updatedInredients);
  // };

  const handleEachIngredientAmount = (e) => {
    const selectedIngredientSlug = e.target.name;
    const selectedIngredientAmount = e.target.value;

    // update the selected ingredinets
    const updatedIngredients = recipeIngredients.map((ingredient) => {
      if (ingredient.slug === selectedIngredientSlug) {
        return {
          ...ingredient,
          amount_of_ingredient: selectedIngredientAmount,
        };
      }
      return ingredient;
    });

    // update with latest data
    setrecipeIngredients(updatedIngredients);
  };

  useEffect(() => {
    getallIngredientItemWithoutPagination();
  }, []);
  // console.log(ingredientItemList);
  return (
    <>
      <Helmet>
        <title>{_t(t("Add New Recipe"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    <div key="smtp-form">
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Add new recipe"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-7">
                          <div className="row gx-3 align-items-center"></div>
                        </div>
                      </div>
                      {/* Form starts here */}
                      <form
                        className="row card p-2 mx-3 mb-5 sm-text"
                        onSubmit={handleSubmit}
                      >
                        <div className="col-12">
                          <div className="form-group mt-3">
                            <div className="mb-2">
                              <label htmlFor="name" className="control-label">
                                {_t(t("Name"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={recipeItem.name}
                                onChange={(e) => {
                                  setrecipeItem({
                                    ...recipeItem,
                                    name: e.target.value,
                                  });
                                }}
                                // value={recipeItem.name}
                                placeholder="e.g. Spicy Chicken Curry"
                                required
                              />
                            </div>
                          </div>

                          <div className="form-group mt-3">
                            <div className="mb-2">
                              <label
                                htmlFor="instruction"
                                className="control-label"
                              >
                                {_t(t("Instructions"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="mb-2">
                              <textarea
                                type="text"
                                className="form-control"
                                id="instruction"
                                name="instruction"
                                value={recipeItem.instruction}
                                onChange={(e) => {
                                  setrecipeItem({
                                    ...recipeItem,
                                    instructions: e.target.value,
                                  });
                                }}
                                // value={recipeItem.name}
                                placeholder="e.g. Spicy Chicken Curry"
                                required
                              ></textarea>
                            </div>
                          </div>

                          <div className="form-group mt-2">
                            <div className="mb-2">
                              <label className="control-label">
                                {_t(t("Add ingredients "))}{" "}
                                <span className="text-danger">
                                  (First, select all ingredients, and then add
                                  the quantity)
                                </span>
                              </label>
                            </div>
                            <Select
                              options={ingredientallItemList}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              isMulti
                              maxMenuHeight="200px"
                              onChange={handleIngredientItems}
                              placeholder={
                                _t(t("Please select property groups")) + ".."
                              }
                            />
                          </div>

                          {recipeIngredients &&
                            recipeIngredients.length > 0 &&
                            recipeIngredients.map((eachIngredients) => {
                              return (
                                <div
                                  className="form-group mt-3 ml-4"
                                  key={eachIngredients.id}
                                >
                                  <div className="mb-2">
                                    <label
                                      htmlFor={eachIngredients.name}
                                      className="control-label"
                                    >
                                      {eachIngredients.name},(
                                      {_t(t("Qty, gm,"))})
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      type="number"
                                      className="form-control"
                                      id={eachIngredients.name}
                                      name={eachIngredients.slug}
                                      onChange={handleEachIngredientAmount}
                                      // value={newItem.name}
                                      placeholder="e.g. 10"
                                      required
                                    />
                                  </div>
                                </div>
                              );
                            })}

                          <div className="form-group mt-5 pb-2">
                            <div className="col-lg-12">
                              <button
                                disabled={
                                  (recipeIngredients &&
                                    recipeIngredients.length === 0) ||
                                  recipeIngredients == null
                                }
                                className="btn btn-primary px-5"
                                type="submit"
                              >
                                {isDataSending
                                  ? _t(t("Saving"))
                                  : _t(t("Save"))}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default RecipeBuilder;
